import InquiryService from "@/services/inquiry.service.js";

export default {
    showInquiry(ctx, id) {
       return InquiryService.showInquiry(ctx, id);
    },
    indexAvailableDocuments(ctx) {
        return InquiryService.indexAvailableDocuments(ctx);
    },
    index(ctx) {
        return InquiryService.index(ctx);
    },
    store(ctx,{payload}){
        return InquiryService.store(ctx,{payload});
    },
    patch(ctx,{id, payload, onlySections, finish })
    {
        return InquiryService.patch(ctx,{id, payload, onlySections, finish })
    },
    destroy(ctx,id){
        return InquiryService.destroy(ctx,id);
    }
    
}