<template>
  <div>
    <v-form @submit.prevent="submit" v-model="isValid">
      <div v-if="!isTemplate">
        <div class="text-overline mt-4">
          Zuweisung

          <util-info-tooltip>
            <template v-slot:text>
              Wählen Sie hier aus, um welche(n) Kunden/Kundin es sich handelt
              und ob die Anfrage von einem/einer einzelnen Berater*in oder einem
              Team bearbeitet wird. Sowohl Berater*in als auch Team können genau
              festgelegt werden.
            </template>
          </util-info-tooltip>
        </div>
        <v-autocomplete
          v-model="inquiry.customer_id"
          :items="users_extern"
          filled
          label="Kunde"
          hide-no-data
          :rules="[rules.required]"
          required
          item-text="name"
          item-value="id"
          solo
        >
          <template v-slot:prepend-item>
            <v-list-item ripple @click="customerDialog = true">
              <v-list-item-avatar>
                <v-icon>mdi-plus</v-icon>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title>
                  Neuen Kunden erstellen
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-divider class="mt-2"></v-divider>
          </template>
          <template v-slot:item="data">
            <v-list-item-content>
              <v-list-item-title>{{ data.item.name }}</v-list-item-title>
              <v-list-item-subtitle v-if="data.item.label">
                Kundennummer: {{ data.item.label }}
              </v-list-item-subtitle>
              <v-list-item-subtitle v-if="data.item.customerType_name">
                <v-chip x-small>{{ data.item.customerType_name }}</v-chip>
              </v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-action>
              <v-dialog
                v-model="data.item.editDialog"
                persistent
                max-width="700px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn depressed v-bind="attrs" v-on="on">
                    Bearbeiten
                    <v-icon right>mdi-pencil</v-icon>
                  </v-btn>
                </template>
                <v-card>
                  <v-card-text>
                    <util-user-form
                      type="dialog"
                      v-on:closeDialog="
                        data.item.editDialog = false;
                        customerEditDialogKey++;
                      "
                      v-on:patchUser="
                        data.item.editDialog = false;
                        inquiry.customer_id = $event;
                        indexUsers();
                        customerEditDialogKey++;
                      "
                      :userId="data.item.id"
                      :key="customerEditDialogKey"
                    />
                  </v-card-text>
                </v-card>
              </v-dialog>
            </v-list-item-action>
          </template>
        </v-autocomplete>

        <v-select
          v-model="inquiry.assignable_type"
          @input="setAssignee()"
          filled
          :items="[
            { text: 'Berater', value: 'App\\Models\\User' },
            { text: 'Team', value: 'App\\Models\\Team' }
          ]"
          label="Bearbeitertyp"
          :rules="[rules.required]"
          required
          solo
        />

        <v-autocomplete
          v-if="inquiry.assignable_type === 'App\\Models\\User'"
          v-model="inquiry.assignable_id"
          :items="users_intern"
          filled
          label="Berater"
          :rules="[rules.required]"
          required
          item-text="name"
          item-value="id"
          solo
        >
          <template v-slot:item="data">
            <v-list-item-content>
              <v-list-item-title>{{ data.item.name }}</v-list-item-title>
              <!--              <v-list-item-subtitle v-if="data.item.label">
                {{ data.item.label }}
              </v-list-item-subtitle>-->
              <v-list-item-subtitle v-if="data.item.email">
                {{ data.item.email }}
              </v-list-item-subtitle>
            </v-list-item-content>
          </template>
        </v-autocomplete>

        <v-autocomplete
          v-if="inquiry.assignable_type === 'App\\Models\\Team'"
          v-model="inquiry.assignable_id"
          :items="teams"
          filled
          label="Team"
          :rules="[rules.required]"
          required
          item-text="name"
          item-value="id"
          solo
        >
          <template v-slot:item="data">
            <v-list-item-content>
              <v-list-item-title>{{ data.item.name }}</v-list-item-title>
              <!--              <v-list-item-subtitle v-if="data.item.label">
                {{ data.item.label }}
              </v-list-item-subtitle>-->
              <v-list-item-subtitle v-if="data.item.description">
                {{ data.item.description }}
              </v-list-item-subtitle>
            </v-list-item-content>
          </template>
        </v-autocomplete>

        <v-autocomplete
          v-if="inquiry.assignable_type === 'App\\Models\\Team'"
          v-model="inquiry.contact_person_id"
          :items="users_intern"
          filled
          label="Ansprechpartner"
          :rules="[rules.required]"
          required
          item-text="name"
          item-value="id"
          solo
        >
          <template v-slot:item="data">
            <v-list-item-content>
              <v-list-item-title>{{ data.item.name }}</v-list-item-title>
              <!--              <v-list-item-subtitle v-if="data.item.label">
                {{ data.item.label }}
              </v-list-item-subtitle>-->
              <v-list-item-subtitle v-if="data.item.email">
                {{ data.item.email }}
              </v-list-item-subtitle>
            </v-list-item-content>
          </template>
        </v-autocomplete>
      </div>

      <div class="text-overline mt-4">
        Allgemein

        <util-info-tooltip>
          <template v-slot:text>
            Tragen Sie hier eine kurze Bezeichnung ein, worum es sich bei der
            Anfrage handelt.
          </template>
        </util-info-tooltip>
      </div>
      <v-text-field
        v-model="inquiry.name"
        filled
        label="Anliegen"
        :rules="[rules.required, rules.short_text]"
        :counter="280"
        solo
      />
    </v-form>
    <v-dialog v-model="customerDialog" max-width="700px">
      <v-card>
        <v-card-text>
          <util-user-form
            type="dialog"
            v-on:closeDialog="customerDialog = false"
            v-on:storeUser="
              customerDialog = false;
              inquiry.customer_id = $event;
              indexUsers();
            "
          />
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  props: {
    isTemplate: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    users_intern: [],
    users_extern: [],
    teams: [],
    // functional
    isValid: false,
    customerEditDialogKey: 0,
    customerDialog: false
  }),
  computed: {
    inquiry() {
      return this.$store.getters["inquiry/currentInquiry"];
    },

    rules() {
      return this.$store.getters["application/rules"];
    },

    userDetails() {
      return this.$store.getters["authentication/userDetails"];
    }
  },
  watch: {
    isValid(val) {
      this.$emit("validation", val);
    }
  },
  created() {
    if (!this.isTemplate) {
      this.indexUsers();
      this.indexTeams();
    }
  },
  methods: {
    indexTeams() {
      this.axios
        .get("/teams")
        .then(res => {
          this.teams = res.data.teams;
        })
        .catch(err => {
          console.log(err);
          this.$snotify.error("Ein Fehler ist aufgetreten.");
        });
    },
    indexUsers() {
      this.axios
        .get("/users")
        .then(res => {
          this.users_intern = res.data.users_intern;
          this.users_extern = res.data.users_extern;
        })
        .catch(err => {
          console.log(err);
          this.$snotify.error("Ein Fehler ist aufgetreten.");
        });
    },

    // functional
    submit() {
      console.log("Ovde usbmita")
      this.$emit("submit");
    },

    setAssignee() {
      if (!this.isTemplate) {
        if (this.inquiry.assignable_type === "App\\Models\\User") {
          this.inquiry.assignable_id = this.userDetails.id;
          this.inquiry.contact_person_id = "";
        }
        if (this.inquiry.assignable_type === "App\\Models\\Team") {
          this.inquiry.assignable_id = "";
          this.inquiry.contact_person_id = this.userDetails.id;
        }
      }
    }
  }
};
</script>

<style scoped></style>
