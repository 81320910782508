var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-list-item',[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" "+_vm._s(_vm.inquiryItemProp.name)+" ")]),_c('v-list-item-subtitle',[_vm._v(" "+_vm._s(_vm.inquiryItemProp.description)+" ")])],1),_c('v-list-item-action',{staticClass:"d-flex flex-row align-center"},[_c('div',{staticClass:"mr-3",staticStyle:{"pointer-events":"none"}},[_c('v-btn',{attrs:{"text":"","color":_vm.statusColor}},[_vm._v(" "+_vm._s(_vm.statusText)+" "),_c('v-icon',{attrs:{"right":"","color":_vm.statusColor}},[_vm._v(" "+_vm._s(_vm.statusIcon)+" ")])],1)],1),(
        _vm.inquiryItemProp.attachable && _vm.inquiryItemProp.attachable.is_outbound
      )?_c('v-btn',{attrs:{"depressed":"","min-width":"200","loading":_vm.downloadLoading,"disabled":_vm.isPreview},on:{"click":function($event){return _vm.downloadFile(
          _vm.inquiryItemProp.attachable.upload.id,
          _vm.inquiryItemProp.attachable.upload.filename_original
        )}}},[_vm._v(" "+_vm._s(_vm.statusActionText)+" "),_c('v-badge',{attrs:{"value":_vm.$hasPermissions('consultant permission')
            ? _vm.inquiryItemProp.notifications.length
            : null,"content":_vm.$hasPermissions('consultant permission')
            ? _vm.inquiryItemProp.notifications.length + ' Neu'
            : null}},[_c('v-icon',{attrs:{"right":""}},[_vm._v(" "+_vm._s(_vm.statusActionIcon)+" ")])],1)],1):_c('v-btn',{attrs:{"depressed":"","min-width":"200","disabled":_vm.isPreview,"to":{
        name: 'InquiryItemsSharesShow',
        params: { id: _vm.inquiryItemProp.id },
      }}},[_vm._v(" "+_vm._s(_vm.statusActionText)+" "),_c('v-badge',{attrs:{"value":_vm.$hasPermissions('consultant permission')
            ? _vm.inquiryItemProp.notifications.length
            : null,"content":_vm.$hasPermissions('consultant permission')
            ? _vm.inquiryItemProp.notifications.length + ' Neu'
            : null}},[_c('v-icon',{attrs:{"right":""}},[_vm._v(" "+_vm._s(_vm.statusActionIcon)+" ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }