var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',{staticClass:"flex-nowrap",attrs:{"justify":"space-between"}},[_c('v-col',{attrs:{"cols":"12","md":"11"}},[(_vm.milestoneProp.milestone_progress < 1)?_c('v-chip',{staticClass:"mb-4",attrs:{"label":"","small":"","color":"warning"}},[_vm._v(" Noch nicht erreicht ("+_vm._s(Math.round(_vm.progressValue))+"%) ")]):(
          _vm.milestoneProp.is_autorelease ||
            _vm.milestoneProp.release_state === 'success'
        )?_c('v-chip',{staticClass:"mb-4",attrs:{"label":"","dark":"","small":"","color":"success"}},[_c('v-icon',{attrs:{"left":"","small":""}},[_vm._v("mdi-check-circle")]),_vm._v(" Zwischenziel erreicht ")],1):(!_vm.milestoneProp.is_autorelease)?_c('v-chip',{staticClass:"mb-4",attrs:{"label":"","dark":"","small":"","color":"success"}},[_c('v-icon',{attrs:{"left":"","small":""}},[_vm._v("mdi-check-circle")]),_vm._v(" Alle Anforderungen erfüllt - nun sind wir dran! ")],1):_vm._e(),(_vm.milestoneProp.name)?_c('div',{staticClass:"text-h5 mb-4",domProps:{"innerHTML":_vm._s(_vm.milestoneProp.name)}}):_vm._e(),(_vm.milestoneProp.description)?_c('div',{staticClass:"body-1 mb-4",domProps:{"innerHTML":_vm._s(_vm.milestoneProp.description)}}):_vm._e(),_c('div',{staticClass:"body-1 mt-4 grey--text text--darken-2 font-weight-light"},[(_vm.milestoneProp.milestone_progress < 1)?_c('span',[_vm._v(" Für das Erreichen dieses Zwischenziels werden noch weitere Unterlagen benötigt ")]):(
            _vm.milestoneProp.is_autorelease ||
              _vm.milestoneProp.release_state === 'success'
          )?_c('span',[_vm._v(" Sie haben erfolgreich alle Anforderungen für das Erreichen des Zwischenziels erfüllt. ")]):(!_vm.milestoneProp.is_autorelease)?_c('span',[_vm._v(" Sie haben erfolgreich alle Anforderungen für das Erreichen des Zwischenziels erfüllt."),_c('br'),_vm._v(" Wir bearbeiten nun Ihr Anliegen. ")]):_vm._e()])],1),(
        (_vm.$hasPermissions('consultant permission') &&
          !_vm.isPreview &&
          !_vm.isShare) ||
          (_vm.isShare && _vm.sharePermission === 'write')
      )?_c('v-col',{attrs:{"cols":"12","md":"auto"}},[_c('v-menu',{attrs:{"offset-y":"","bottom":"","left":"","transition":"slide-y-transition"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
      var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-dots-vertical")])],1)]}}],null,false,3221905750)},[_c('v-list',[_c('v-dialog',{attrs:{"max-width":"600","persistent":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
      var attrs = ref.attrs;
return [_c('v-list-item',_vm._g(_vm._b({attrs:{"link":"","disabled":_vm.milestoneProp.release_state === 'success'}},'v-list-item',attrs,false),on),[_vm._v(" Als erfüllt kennzeichnen ")])]}}],null,false,3840849260),model:{value:(_vm.milestoneProp.releaseDialog1),callback:function ($$v) {_vm.$set(_vm.milestoneProp, "releaseDialog1", $$v)},expression:"milestoneProp.releaseDialog1"}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"headline"},[_vm._v(" Zwischenziel als erfüllt kennzeichnen ")])]),_c('v-card-text',[_vm._v(" Kennzeichnen Sie ein Zwischenziel als erfüllt, um dem Kunden mitzuteilen, dass er diesen Bereich der Anfrage erfolgreich bearbeitet hat. ")]),_c('v-card-text',{staticClass:"d-flex justify-end mt-4"},[_c('v-btn',{attrs:{"text":""},on:{"click":function($event){_vm.milestoneProp.releaseDialog1 = false}}},[_vm._v(" Abbrechen ")]),_c('v-btn',{staticClass:"ml-2",attrs:{"color":"primary","dark":""},on:{"click":function($event){_vm.milestoneProp.releaseDialog1 = false;
                    _vm.release('success');}}},[_vm._v(" Als erfüllt kennzeichnen "),_c('v-icon',{attrs:{"right":""}},[_vm._v(" mdi-check-circle ")])],1)],1)],1)],1)],1)],1)],1):_vm._e()],1),_c('div')],1)}
var staticRenderFns = []

export { render, staticRenderFns }