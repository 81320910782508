<template>
  <v-navigation-drawer
    color="white"
    app
    expand-on-hover
    mobile-breakpoint="0"
    clipped
  >
    <v-list dense nav rounded>
      <v-list-item class="mt-2" link :to="{ name: 'Dashboard' }" exact>
        <v-list-item-icon>
          <v-icon>mdi-view-dashboard-outline</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title class="font-weight-medium">
            Dashboard
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item
        link
        :to="{ name: 'Inquiries', query: { type: 'assigned', view: 'column' } }"
        v-if="$hasPermissions('consultant permission')"
      >
        <v-list-item-icon>
          <v-icon>mdi-text-box-multiple-outline</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title class="font-weight-medium">
            Anfragen
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item
        link
        :to="{ name: 'Inquiries' }"
        v-if="$hasPermissions('customer permission')"
      >
        <v-list-item-icon>
          <v-icon>mdi-text-box-multiple-outline</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title class="font-weight-medium">
            Anfragen
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item
        link
        v-if="$hasPermissions('consultant permission')"
        :to="{ name: 'TeamsColumn' }"
      >
        <v-list-item-icon>
          <v-icon>mdi-account-multiple-outline</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title class="font-weight-medium">
            Teams
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>

    <v-divider
      class="grey lighten-1"
      style="border-width: 1px"
      v-if="$hasPermissions('settings permission')"
    />

    <v-list nav rounded v-if="$hasPermissions('settings permission')">
      <v-list-group no-action>
        <template v-slot:activator>
          <v-list-item-icon>
            <v-icon>mdi-cog-outline</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title class="font-weight-medium">
              Einstellungen
            </v-list-item-title>
          </v-list-item-content>
        </template>
        <v-list-group
          no-action
          sub-group
          v-if="$hasPermissions('subject-admin permission')"
        >
          <template v-slot:activator>
            <v-list-item-content>
              <v-list-item-title class="font-weight-medium">
                Anfragen
              </v-list-item-title>
            </v-list-item-content>
          </template>
          <v-list-item link :to="{ name: 'Documents' }">
            <v-list-item-content>
              <v-list-item-title class="font-weight-medium">
                Dokumente
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item link :to="{ name: 'DocumentTypes' }">
            <v-list-item-content>
              <v-list-item-title class="font-weight-medium">
                Dokumententypen
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item link :to="{ name: 'DocumentFormats' }">
            <v-list-item-content>
              <v-list-item-title class="font-weight-medium">
                Dateiformate
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item link :to="{ name: 'SettingsInquiries' }">
            <v-list-item-content>
              <v-list-item-title class="font-weight-medium">
                Vorlagen
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-group>
        <v-list-group
          no-action
          sub-group
          v-if="$hasPermissions('subject-admin permission')"
        >
          <template v-slot:activator>
            <v-list-item-content>
              <v-list-item-title class="font-weight-medium">
                Produkte
              </v-list-item-title>
            </v-list-item-content>
          </template>
          <v-list-item link :to="{ name: 'Products' }">
            <v-list-item-content>
              <v-list-item-title class="font-weight-medium">
                Produkte
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item link :to="{ name: 'ProductForms' }">
            <v-list-item-content>
              <v-list-item-title class="font-weight-medium">
                Formulare
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-group>
        <v-list-group
          no-action
          sub-group
          v-if="$hasPermissions('technical-admin permission')"
        >
          <template v-slot:activator>
            <v-list-item-content>
              <v-list-item-title class="font-weight-medium">
                Personen
              </v-list-item-title>
            </v-list-item-content>
          </template>

          <v-list-item link :to="{ name: 'Users' }">
            <v-list-item-content>
              <v-list-item-title class="font-weight-medium">
                Nutzer
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item link :to="{ name: 'Teams' }">
            <v-list-item-content>
              <v-list-item-title class="font-weight-medium">
                Teams
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item link :to="{ name: 'CustomerTypes' }">
            <v-list-item-content>
              <v-list-item-title class="font-weight-medium">
                Kundentypen
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-group>
        <v-list-group
          no-action
          sub-group
          v-if="$hasPermissions('technical-admin permission')"
        >
          <template v-slot:activator>
            <v-list-item-content>
              <v-list-item-title class="font-weight-medium">
                Statistiken
              </v-list-item-title>
            </v-list-item-content>
          </template>

          <v-list-item link :to="{ name: 'InquiriesStatistics' }">
            <v-list-item-content>
              <v-list-item-title class="font-weight-medium">
                Anfragen
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-group>
        <v-list-item
          link
          :to="{ name: 'System' }"
          v-if="$hasPermissions('technical-admin permission')"
        >
          <v-list-item-content>
            <v-list-item-title class="font-weight-medium">
              System
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list-group>
    </v-list>
  </v-navigation-drawer>
</template>
<script>
export default {
  name: "drawer",

  // TODO: active states
};
</script>

<style scoped>
.navigation-drawer__border {
  width: 10px !important;
  background-color: red !important;
}
</style>