var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"max-width":"600px","persistent":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"depressed":""}},'v-btn',attrs,false),on),[_vm._v(" Zugriffsberechtigungen verwalten "),_c('v-icon',{attrs:{"right":"","dark":""}},[_vm._v("mdi-share-variant-outline")])],1)]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('ui-dialog-card',{attrs:{"title":"Zugriffsberechtigungen verwalten"},scopedSlots:_vm._u([{key:"text",fn:function(){return [_c('v-form',{ref:"form",on:{"submit":function($event){$event.preventDefault();return _vm.submit.apply(null, arguments)}},model:{value:(_vm.isValid),callback:function ($$v) {_vm.isValid=$$v},expression:"isValid"}},[_c('div',{staticClass:"text-overline mb-4"},[_vm._v(" Zugriffsberechtigung hinzufügen ")]),_c('v-slide-y-transition',{attrs:{"group":""}},[_c('v-select',{key:"selectAccessorType",attrs:{"items":[
              { value: 'user', text: 'Berater' },
              { value: 'team', text: 'Team' }
            ],"filled":"","label":"Zugreifertyp","rules":[_vm.rules.required],"required":"","solo":""},model:{value:(_vm.share.accessor_type),callback:function ($$v) {_vm.$set(_vm.share, "accessor_type", $$v)},expression:"share.accessor_type"}}),(_vm.share.accessor_type === 'user')?_c('v-select',{key:"selectAccessorUser",attrs:{"items":_vm.users,"filled":"","label":"Berater","rules":[_vm.rules.required],"required":"","item-text":"name","item-value":"id","solo":""},model:{value:(_vm.share.accessor_id),callback:function ($$v) {_vm.$set(_vm.share, "accessor_id", $$v)},expression:"share.accessor_id"}}):_vm._e(),(_vm.share.accessor_type === 'team')?_c('v-select',{key:"selectAccessorTeam",attrs:{"items":_vm.teams,"filled":"","label":"Team","rules":[_vm.rules.required],"required":"","item-text":"name","item-value":"id","solo":""},model:{value:(_vm.share.accessor_id),callback:function ($$v) {_vm.$set(_vm.share, "accessor_id", $$v)},expression:"share.accessor_id"}}):_vm._e(),(_vm.share.accessor_type && _vm.share.accessor_id)?_c('v-textarea',{key:"selectAccessorHint",attrs:{"filled":"","label":"Hinweis für die Bearbeiter","solo":""},model:{value:(_vm.share.hint),callback:function ($$v) {_vm.$set(_vm.share, "hint", $$v)},expression:"share.hint"}}):_vm._e(),(_vm.share.accessor_type && _vm.share.accessor_id)?_c('div',{key:"accessorButtons",staticClass:"d-flex justify-end"},[_c('v-btn',{attrs:{"text":""},on:{"click":function($event){_vm.share.accessor_type = '';
                _vm.share.accessor_id = '';
                _vm.share.hint = '';
                _vm.$refs.form.resetValidation();}}},[_vm._v(" Abbrechen ")]),_c('v-btn',{staticClass:"ml-2",attrs:{"color":"primary","loading":_vm.loading,"disabled":!_vm.isValid},on:{"click":_vm.storeShare}},[_vm._v(" Hinzufügen "),_c('v-icon',{attrs:{"right":"","dark":""}},[_vm._v("mdi-plus")])],1)],1):_vm._e()],1)],1),_c('div',{staticClass:"text-overline mb-4"},[_vm._v(" Erteilte Zugriffsberechtigungen ("+_vm._s(_vm.inquiryItem.shares.length)+") ")]),(_vm.inquiryItem.shares.length < 1)?_c('div',{staticClass:"body-1"},[_vm._v(" Es wurden bisher keine Zugriffe erteilt. ")]):_c('div',_vm._l((_vm.inquiryItem.shares),function(share){return _c('v-list-item',{key:share.id,staticClass:"px-0"},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" "+_vm._s(_vm._f("moment")(share.created_at,"DD.MM.YYYY HH:mm"))+" ")]),_c('v-list-item-subtitle',[_vm._v(" Erteilt an "+_vm._s(share.accessor_type === "App\\Models\\Team" ? "Team: " : "Berater: ")+" "+_vm._s(share.accessor.name)+" ")])],1),_c('v-list-item-action',[_c('v-btn',{attrs:{"depressed":"","to":{ name: 'SharesShow', params: { id: share.id } }}},[_vm._v(" Ansehen "),_c('v-icon',{attrs:{"right":""}},[_vm._v("mdi-eye-outline")])],1)],1)],1)}),1)]},proxy:true},{key:"actions",fn:function(){return [_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","block":""},on:{"click":_vm.close}},[_vm._v(" Okay ")])]},proxy:true}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }