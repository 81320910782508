import store from "../store";

export default permissions => {
  const userPermissions = store.getters["authentication/permissions"];
  let canEnter = false;

  if (!userPermissions) {
    return false;
  }

  if (!permissions) {
    return true;
  }

  if (!Array.isArray(permissions)) {
    canEnter = userPermissions.includes(permissions);
  } else {
    permissions.forEach(permission => {
      if (userPermissions.includes(permission)) {
        canEnter = true;
      }
    });
  }

  return canEnter;
};
