<template>
  <v-dialog max-width="600px" v-model="dialog" persistent>
    <template v-slot:activator="{ on, attrs }">
      <v-btn color="primary" block v-bind="attrs" v-on="on" v-if="!sectionProp">
        {{ sectionProp ? "Abschnitt bearbeiten" : "Abschnitt hinzufügen" }}
        <v-icon right dark>mdi-plus</v-icon>
      </v-btn>
      <v-btn icon v-bind="attrs" v-on="on" v-else>
        <v-icon>mdi-pencil-outline</v-icon>
      </v-btn>
    </template>
    <ui-dialog-card
      :title="sectionProp ? 'Abschnitt bearbeiten' : 'Abschnitt hinzufügen'"
    >
      <template v-slot:text>
        <v-form @submit.prevent="submit" v-model="isValid">
          <v-text-field
            v-model="section.name"
            filled
            label="Name*"
            :rules="[rules.required, rules.short_text]"
            :counter="280"
            solo
          />
          <v-textarea
            v-model="section.description"
            filled
            label="Beschreibung"
            :rules="[rules.long_text]"
            :counter="6000"
            solo
          />

          <div>
            <v-checkbox
              v-model="section.is_autorelease"
              label="Abschnitt wird automatisch freigegeben"
            />

            <v-dialog
              v-if="section.is_autorelease && !isTemplate"
              ref="releasedAtDialog"
              v-model="releasedAtModal"
              :return-value.sync="section.released_at"
              persistent
              width="290px"
            >
              <template #activator="{ on, attrs }">
                <v-text-field
                  :value="formattedReleasedAt"
                  label="Freigabe zum Tag"
                  prepend-inner-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  filled
                  clearable
                  v-on="on"
                  @click:clear="section.released_at = ''"
                  solo
                />
              </template>
              <v-date-picker
                v-model="section.released_at"
                scrollable
                :min="currentDate"
              >
                <v-spacer />
                <v-btn
                  text
                  color="primary"
                  class="mr-2"
                  @click="releasedAtModal = false"
                >
                  Abbrechen
                </v-btn>
                <v-btn
                  color="primary"
                  @click="$refs.releasedAtDialog.save(section.released_at)"
                >
                  Okay
                </v-btn>
              </v-date-picker>
            </v-dialog>
          </div>
        </v-form>
      </template>

      <template v-slot:actions>
        <v-spacer />
        <v-btn text @click="close" class="mr-2">
          Abbrechen
        </v-btn>
        <v-btn
          color="primary"
          @click="submit"
          :disabled="!isValid"
          :loading="loading"
        >
          {{ sectionProp ? "Abschnitt speichern" : "Abschnitt hinzufügen" }}
          <v-icon right dark v-if="!sectionProp">
            mdi-plus
          </v-icon>
        </v-btn>
      </template>
    </ui-dialog-card>
  </v-dialog>
</template>

<script>
import { mapActions } from "vuex";

export default {
  props: {
    isTemplate: {
      type: Boolean,
      default: false
    },
    sectionProp: {
      type: Object,
      default: () => {}
    }
  },
  data: () => ({
    section: {},

    // functional
    releasedAtModal: false,
    dialog: false,
    isValid: false,
    loading: false
  }),
  computed: {
    rules() {
      return this.$store.getters["application/rules"];
    },
    formattedReleasedAt() {
      return this.section.released_at
        ? this.$moment(this.section.released_at).format("DD.MM.YYYY")
        : null;
    },
    currentDate() {
      return this.$moment(new Date()).format("YYYY-MM-DD");
    }
  },
  watch: {
    sectionProp(val) {
      this.section = val;
    }
  },
  created() {
    this.section = this.sectionProp ? this.sectionProp : this.section;
  },
  methods: {
    ...mapActions({
      showCurrentInquiry: "inquiry/showInquiry",
      indexAvailableDocuments: "inquiry/indexAvailableDocuments",
      patchInquiryItem: "inquiryItem/patch"
    }),

    patchSection() {
      this.patchInquiryItem({ id: this.section.id, payload: this.section })
        .then(() => {
          this.$snotify.success("Der Abschnitt wurde erfolgreich bearbeitet.");
          this.close();
        })
        .catch(e => {
          console.log(e);
          this.$snotify.error(
            "Beim Bearbeiten des Abschnitts ist ein Fehler aufgetreten."
          );
        });
    },
    storeSection() {
      this.section.inquiry_id = this.$route.params.id;
      this.axios
        .post("inquiry-items", {
          inquiryItem: this.section
        })
        .then(() => {
          this.showCurrentInquiry(this.$route.params.id);
          this.$snotify.success("Der Abschnitt wurde erfolgreich hinzugefügt.");
          this.close();
        })
        .catch(e => {
          console.log(e);
          this.$snotify.error(
            "Beim Hinzufügen des Abschnitts ist ein Fehler aufgetreten."
          );
        });
    },

    // functional
    close() {
      this.dialog = false;
      this.loading = false;
      this.$emit("close");
      this.indexAvailableDocuments();
    },

    submit() {
      this.loading = true;
      this.section.id ? this.patchSection() : this.storeSection();
    }
  }
};
</script>

<style scoped></style>
