<template>
  <v-dialog max-width="500px" v-model="dialog" persistent>
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        :color="isText ? 'red' : 'red'"
        class="white--text"
        block
        v-bind="attrs"
        v-on="on"
      >
        <div v-if="isText">Prüfungsstatus ändern</div>
        
        <div v-else>
          <v-row>
          <!-- :text="isText" -->
          {{
            $hasPermissions("consultant permission")
              ? "Unterlagen prüfen"
              : "Unterlagen freigeben"
          }}
          <v-icon right :dark="!isText"
            >mdi-checkbox-multiple-marked-circle-outline</v-icon
          >
          </v-row>
        </div>
      </v-btn>
    </template>
    <ui-dialog-card
      :title="
        $hasPermissions('consultant permission')
          ? 'Sind Sie sich sicher, dass Sie die Unterlagen nun prüfen wollen?'
          : 'Sind Sie sich sicher, dass Sie die Unterlagen nun freigeben wollen?'
      "
    >
      <template v-slot:text>
        <div v-if="$hasPermissions('consultant permission')">
          <p>
            Wenn Sie den Aufruf in den Prüfungsstatus versetzen, hat der Kunde
            keine Möglichkeit mehr in diesem Aufruf Unterlagen hochzuladen.
          </p>
          <p>
            Sie erhalten keinen Zugriff auf Unterlagen des Kunden, welche noch
            nicht freigegeben sind.
          </p>
          Dies kann sinnvoll sein, wenn bspw. nur intern Unterlagen
          bereitgestellt werden sollten.
        </div>
        <div v-else>
          <p>
            Wenn Sie den Aufruf freigeben, lassen Sie Ihrer Bank die
            hochgeladenen Unterlagen zukommen.
          </p>
          <p>
            Sollten Sie fälschlicherweise Unterlagen hochgeladen haben, löschen
            Sie diese im Voraus, da Sie diese im Anschluss nicht mehr entfernen
            können.
          </p>
          Nach Freigabe kann Ihr Berater mit der Prüfung der Unterlagen
          beginnen. Das entsprechende Ergebnis wird Ihnen mitgeteilt.
        </div>
      </template>

      <template v-slot:actions>
        <v-spacer />
        <v-btn text @click="close" class="mr-2"> Abbrechen </v-btn>
        <v-btn color="primary" @click="submit" :loading="loading">
          Bestätigen
        </v-btn>
      </template>
    </ui-dialog-card>
  </v-dialog>
</template>

<script>
import { mapActions } from "vuex";

export default {
  props: {
    itemProp: {
      type: Object,
      default: () => {},
    },
    isText: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    item: {},

    // functional
    dialog: false,
    isValid: false,
    loading: false,
  }),
  computed: {
    uploadRequest() {
      return this.$store.getters["inquiryItem/inquiryItem"]
        .currentUploadRequest;
    },
    rules() {
      return this.$store.getters["application/rules"];
    },
  },
  watch: {
    itemProp(val) {
      this.section = val;
    },
  },
  created() {
    // this.item = this.itemProp ? this.itemProp : this.item;
  },
  methods: {
    ...mapActions({
      showInquiryItem: "inquiryItem/show",
    }),

    changeState() {
      this.loading = true;
      this.axios
        .patch("/upload-requests/" + this.uploadRequest.id + "?state=received")
        .then(() => {
          this.$snotify.success(
            "Der Status des Aufrufs wurde erfolgreich aktualisiert."
          );
          this.showInquiryItem({ id: this.$route.params.id });
          this.close();
        })
        .catch((e) => {
          console.log(e);
          this.loading = false;
        });
    },

    // functional
    close() {
      this.dialog = false;
      this.loading = false;
      this.$emit("close");
    },

    submit() {
      this.loading = true;
      // function
      this.changeState();
    },
  },
};
</script>

<style scoped></style>
