<template>
  <div>
 
    <!--Search & Sort-->
    <v-row class="mt-5" justify="center">
      <v-col cols md="4">
        <v-text-field
          solo
          solo-inverted
          flat
          placeholder="Suchen"
          prepend-inner-icon="mdi-magnify"
          dense
          hide-details
          v-model="search"
        />
      </v-col>
      <v-col cols md="2">
        <v-select
          solo
          solo-inverted
          flat
          placeholder="Sortieren"
          prepend-inner-icon="mdi-magnify"
          dense
          hide-details
          :items="keys"
          v-model="sortBy"
        />
      </v-col>
      <v-col cols md="1">
        <v-btn-toggle v-model="sortDesc" mandatory style="width: 50%">
          <v-btn
            block
            height="38px"
            depressed
            color="grey lighten-3"
            :value="false"
          >
            <v-icon>mdi-arrow-up</v-icon>
          </v-btn>
          <v-btn
            block
            height="38px"
            depressed
            color="grey lighten-3"
            :value="true"
          >
            <v-icon>mdi-arrow-down</v-icon>
          </v-btn>
        </v-btn-toggle>
      </v-col>
    </v-row>

    <inquiry-common-list-view
      :sortBy="sortBy"
      :sortDesc="sortDesc"
      :search="search"
    />
  </div>
</template>

<script>
export default {
  props: {},
  data: () => ({
    search: "",
    sortDesc: true,
    sortBy: "last_customer_action_at",
    keys: [
      { value: "last_customer_action_at", text: "Letzte Aktualisierung" },
      { value: "name", text: "Name" }
    ],
    assignedInquiries: 0,
    assignedInquiries_draft: 0,
    sharedWithUser: 0,
    sharedWithTeam: 0
  }),
  computed: {
    tab() {
      switch (this.$route.query.type) {
        case "assigned":
          return 0;
        case "sharedWith":
          return 1;
        case "sharedWithTeams":
          return 2;
        case "drafts":
          return 3;
      }
      return 0;
    }
  },
  watch: {},
  created() {
    this.indexCount();
  },
  methods: {
    indexCount() {
      this.axios
        .get("/dashboard/inquiries?view=count")
        .then(res => {
          this.assignedInquiries = res.data.assignedInquiries;
          this.assignedInquiries_draft = res.data.assignedInquiries_draft;
          this.sharedWithUser = res.data.sharedWithUser;
          this.sharedWithTeam = res.data.sharedWithTeam;
        })
        .catch(err => {
          console.log(err);
          this.$snotify.error("Ein Fehler ist aufgetreten.");
        });
    }
  }
};
</script>

<style scoped></style>