<template>
  <div>
    <v-row justify="space-between" class="flex-nowrap">
      <v-col cols="12" md="11">
        <v-chip
          label
          class="mb-4"
          small
          color="grey lighten-3"
          v-if="!sectionProp.release_state"
        >
          Noch nicht freigegeben
        </v-chip>
        <div
          class="text-h5 mb-4"
          v-if="sectionProp.name"
          v-html="sectionProp.name"
        />
        <div
          class="body-1 mb-4"
          v-if="sectionProp.description"
          v-html="sectionProp.description"
        />
      </v-col>
      <v-col
        cols="12"
        md="auto"
        v-if="
          ($hasPermissions('consultant permission') &&
            !isPreview &&
            !isShare) ||
          (isShare && sharePermission === 'write')
        "
      >
        <v-menu offset-y bottom left transition="slide-y-transition">
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon v-bind="attrs" v-on="on">
              <v-icon>mdi-dots-vertical</v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-dialog
              max-width="600"
              persistent
              v-model="sectionProp.releaseDialog1"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-list-item
                  link
                  v-bind="attrs"
                  v-on="on"
                  :disabled="sectionProp.release_state !== null"
                >
                  Freigeben ohne E-Mail
                </v-list-item>
              </template>
              <v-card>
                <inquiry-consultant-timeline-release :isWithMail="false" />
                <v-card-text class="d-flex justify-end mt-4">
                  <v-btn text @click="sectionProp.releaseDialog1 = false">
                    Abbrechen
                  </v-btn>
                  <v-btn
                    class="ml-2"
                    color="primary"
                    dark
                    @click="
                      sectionProp.releaseDialog1 = false;
                      release('released');
                    "
                  >
                    Freigeben ohne E-Mail
                    <v-icon right> mdi-lock-open-outline </v-icon>
                  </v-btn>
                </v-card-text>
              </v-card>
            </v-dialog>

            <v-dialog
              max-width="600"
              persistent
              v-model="sectionProp.releaseDialog"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-list-item
                  link
                  v-bind="attrs"
                  v-on="on"
                  :disabled="sectionProp.release_state !== null"
                >
                  Freigeben mit E-Mail
                </v-list-item>
              </template>
              <v-card>
                <inquiry-consultant-timeline-release :isWithMail="true" />
                <v-card-text class="d-flex justify-end mt-4">
                  <v-btn text @click="sectionProp.releaseDialog = false">
                    Abbrechen
                  </v-btn>
                  <v-btn
                    class="ml-2"
                    color="primary"
                    dark
                    @click="
                      sectionProp.releaseDialog = false;
                      release('released_with_email');
                    "
                  >
                    Freigeben mit E-Mail
                    <v-icon right> mdi-lock-open-outline </v-icon>
                  </v-btn>
                </v-card-text>
              </v-card>
            </v-dialog>

            <!--TODO: clean-->
            <!--Add documents-->
            <inquiry-wizard-layout-document-dialog
              :section-id="sectionProp.id"
              :is-wizard="false"
            />
            <!-- <v-dialog
              max-width="600"
              persistent
            
            >
              <template v-slot:activator="{ on, attrs }">
                <v-list-item
                  link
                  v-bind="attrs"
                  v-on="on"
                 
                >
                 Share section
                </v-list-item>
              </template>
              <v-card>
               
                <inquiry-shares-section-dialog type="inquiry" />
              </v-card>
            </v-dialog> -->
          </v-list>
        </v-menu>
      </v-col>
    </v-row>

    <div
      v-if="
        $hasPermissions('consultant permission') ||
        sectionProp.release_state !== null
      "
    >
      <v-card>
        <v-list>
          <template v-for="child in sectionProp.children">
            <inquiry-consultant-timeline-list
              :inquiryItemProp="child"
              :key="child.id"
              :is-preview="isPreview"
            />
          </template>
        </v-list>
      </v-card>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    sectionProp: Object,
    isPreview: {
      type: Boolean,
      default: false,
    },
    isShare: {
      type: Boolean,
      default: false,
    },
    sharePermission: {
      type: String,
      default: "read",
    },
  },
  data: () => ({}),
  computed: {},
  watch: {},
  created() {},
  methods: {
    release(release_state) {
      this.axios
        .patch(
          "/inquiry-items/release/" + this.sectionProp.id + "/" + release_state
        )
        .then(() => {
          this.$snotify.success("Der Abschnitt wurde erfolgreich freigegeben.");
          this.sectionProp.release_state = release_state;
        })
        .catch((e) => {
          console.log(e);
          this.$snotify.error(
            "Bei der Freigabe des Abschnitts ist ein Fehler aufgetreten."
          );
        });
    },
  },
};
</script>

<style scoped></style>
