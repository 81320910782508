<template>
  <div>

    <div
      v-if="
        $hasPermissions('consultant permission') ||
        sectionProp.release_state !== null
      "
    >
      <v-card>
        <v-list>
          <template v-for="child in sectionProp.children">
            <inquiry-shares-section-list
              :inquiryItemProp="child"
              :key="child.id"
              :is-preview="isPreview"
            />
          </template>
        </v-list>
      </v-card>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    sectionProp: Object,
    isPreview: {
      type: Boolean,
      default: false,
    },
    isShare: {
      type: Boolean,
      default: false,
    },
    sharePermission: {
      type: String,
      default: "read",
    },
  },
  data: () => ({}),
  computed: {},
  watch: {},
  created() {},
  methods: {
    release(release_state) {
      this.axios
        .patch(
          "/inquiry-items/release/" + this.sectionProp.id + "/" + release_state
        )
        .then(() => {
          this.$snotify.success("Der Abschnitt wurde erfolgreich freigegeben.");
          this.sectionProp.release_state = release_state;
        })
        .catch((e) => {
          console.log(e);
          this.$snotify.error(
            "Bei der Freigabe des Abschnitts ist ein Fehler aufgetreten."
          );
        });
    },
  },
};
</script>

<style scoped></style>
