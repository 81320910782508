<template>
  <v-dialog max-width="600px" v-model="dialog" persistent>
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        :depressed="!useType"
        :block="!useType"
        :color="useType ? 'primary' : ''"
        height="38px"
        v-bind="attrs"
        :dark="!!useType"
        v-on="on"
      >
        {{ isTemplate ? "Neue Vorlage" : "Neue Anfrage" }}
        <v-icon right :dark="!!useType">{{
          !!useType ? "mdi-text-box-plus-outline" : "mdi-plus"
        }}</v-icon>
      </v-btn>
    </template>
    <ui-dialog-card :title="isTemplate ? 'Neue Vorlage' : 'Neue Anfrage'">
      <template v-slot:text>
        <inquiry-wizard-general-basic-form
          :is-template="isTemplate"
          @validation="isValid = $event"
        />
      </template>

      <template v-slot:actions>
        <v-spacer />
        <v-btn text @click="close" class="mr-2">
          Abbrechen
        </v-btn>
        <v-btn
          color="primary"
          @click="submit"
          :disabled="!isValid"
          :loading="loading"
        >
          {{ isTemplate ? "Vorlage hinzufügen" : "Anfrage hinzufügen" }}
          <v-icon right dark>
            mdi-plus
          </v-icon>
        </v-btn>
      </template>
    </ui-dialog-card>
  </v-dialog>
</template>
<script>
import { mapActions } from "vuex";

export default {
  props: {
    isTemplate: {
      type: Boolean,
      default: false
    },
    useType: {
      type: String,
      default: ""
    }
  },
  data: () => ({
    users_intern: [],
    users_extern: [],
    teams: [],

    // functional
    dialog: false,
    isValid: false,
    loading: false
  }),
  computed: {
    inquiry() {
      return this.$store.getters["inquiry/currentInquiry"];
    },
    rules() {
      return this.$store.getters["application/rules"];
    }
  },
  watch: {
    $route() {
      if (this.$route.query.label) {
        this.dialog = true;
        this.getUserIdByLabel();
      }
    }
  },
  created() {
    this.$store.commit("inquiry/setCurrentInquiry", {});

    if (this.$route.query.label) {
      this.dialog = true;
      this.getUserIdByLabel();
    }
    // this.item = this.itemProp ? this.itemProp : this.item;
  },
  methods: {
    ...mapActions({
      storeInquiry: "inquiry/store"
    }),
    getUserIdByLabel() {
      if (this.$route.query.label) {
        this.axios
          .get("/users/check-label/" + this.$route.query.label)
          .then(r => {
            console.log(r.data.id);
            this.inquiry.customer_id = r.data.id;
            console.log(this.inquiry.customer_id);
          })
          .catch(() => {
            this.$snotify.error(
              "Es konnte kein Nutzer der Kundennummer zugeordnet werden."
            );
          });
      }
    },
    // functional
    close() {
      this.dialog = false;
      this.loading = false;
      this.$store.commit("inquiry/setCurrentInquiry", {});
      this.$emit("close");
    },

    submit() {
      this.loading = true;
      this.inquiry.is_template = this.isTemplate;
     //  console.log("ovo pokusavam da dodam"+this.inquiry);
      this.storeInquiry({ payload: this.inquiry })
        .then(r => {

            console.log("ovo pokusavam da dodam"+this.inquiry.data);
          this.isTemplate
            ? this.$router.push({
                name: "SettingsInquiriesEditGeneral",
                params: { id: r.data.id }
              })
            : this.$router.push({
                name: "InquiriesEditGeneral",
                params: { id: r.data.id }
              });
        })
        .catch(e => {
          console.log(e);
          const errorMessage = this.isTemplate
            ? "Beim Speichern der Vorlage ist ein Fehler aufgetreten."
            : "Beim Anfrage der Vorlage ist ein Fehler aufgetreten.";
          this.$snotify.error(errorMessage);
          this.loading = false;
        })
        .finally(() => {
          this.loading = false;
        });
    }
  }
};
</script>

<style scoped></style>
