<template>
  <div>
    <div class="text-overline mb-4" v-if="!isPreview">Anforderungen</div>

    <div v-if="currentInquiry.policy_deleted_at" class="body-1">
      Alle Anforderungen und Unterlagen der Anfrage wurden gelöscht.
    </div>
  
         <inquiry-shares-section-section
          v-if="currentInquiry.inquirySections[0].type === null"
          :sectionProp="currentInquiry.inquirySections[0]"
          :is-preview="isPreview"
          :is-share="isShare"
          :share-permission="sharePermission"
        />
    
     
    <!-- Timeline (inquirySections and inquiryItems) -->
    <!-- <v-timeline
      align-top
      dense
      v-if="currentInquiry && !currentInquiry.policy_deleted_at"
    >
    
       <v-timeline-item
        color="primary"
        v-for="section in currentInquiry.inquirySections"
        :key="section.id"
        class="mb-8"
        :large="section.is_milestone"
      > 
        <template v-slot:icon>
          <v-progress-circular
            :rotate="-90"
            color="secondary"
            :size="50"
            :width="5"
            :value="section.milestone_progress * 100"
          >
          </v-progress-circular>
        </template>
        <inquiry-shares-section-section
          v-if="section.type === null"
          :sectionProp="section"
          :is-preview="isPreview"
          :is-share="isShare"
          :share-permission="sharePermission"
        />
       
      </v-timeline-item> 
    </v-timeline> -->
  </div>
</template>
<script>
export default {
  props: {
    isPreview: {
      type: Boolean,
      default: false
    },
    isShare: {
      type: Boolean,
      default: false
    },
    sharePermission: {
      type: String,
      default: "read"
    }
  },
  data: () => ({}),
  computed: {
    currentInquiry() {
      return this.$store.getters["inquiry/currentInquiry"];
    }
  },
  watch: {},
  created() {},
  methods: {}
};
</script>

<style scoped></style>
