import axios from "axios";

export default {
  companyInfo(ctx) {
    return new Promise((resolve, reject) => {
      axios
        .get("/state")
        .then(response => {
          ctx.commit("setSettings", response.data.settings);
          ctx.commit("setBrandUrl", response.data.brand_url);
          resolve(response.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
};