<template>
  <v-dialog max-width="600px" v-model="dialog" persistent>
    <template v-slot:activator="{ on, attrs }">
      <v-btn color="primary" block v-bind="attrs" v-on="on">
        Prüfungsstatus ändern
        <v-icon right dark>mdi-checkbox-multiple-marked-circle-outline</v-icon>
      </v-btn>
    </template>
    <ui-dialog-card title="Prüfungsstatus ändern">
      <template v-slot:text>
        <v-form @submit.prevent="submit" v-model="isValid" ref="form">
          <v-select
            filled
            item-value="value"
            :items="states"
            v-model="state"
            label="Neuer Prüfungsstatus"
            :rules="[rules.required]"
            solo
          >
            <template v-slot:item="data">
              <v-list-item-action>
                <v-avatar dark size="35" :color="data.item.color">
                  <v-icon size="25" dark>{{ data.item.icon }}</v-icon>
                </v-avatar>
              </v-list-item-action>
              <v-list-item-content>
                {{ data.item.text }}
              </v-list-item-content>
            </template>
            <template v-slot:selection="data">
              <v-avatar dark size="18" :color="data.item.color" class="mr-2">
              </v-avatar>
              {{ data.item.text }}
            </template>
          </v-select>
        </v-form>
        <v-slide-y-transition>
          <div key="acceptedText" v-if="state === 'accepted'">
            Sie teilem dem Kunden mit, dass die Prüfung der Unterlagen
            erfolgreich war. <br />
            Entsprechend verändert sich der Gesamtfortschritt der Anfrage.
          </div>
          <div key="declinedText" v-else-if="state === 'declined'">
            Sie teilem dem Kunden mit, dass die Prüfung der Unterlagen nicht
            erfolgreich war. <br />
            Sollten Sie die Unterlagen erneut einfordern wollen, können Sie das
            über die "Unterlagen erneut anfordern"-Schaltfläche tun.
          </div>
        </v-slide-y-transition>
      </template>

      <template v-slot:actions>
        <v-spacer />
        <v-btn text @click="close" class="mr-2">
          Abbrechen
        </v-btn>
        <v-btn
          color="primary"
          @click="submit"
          :disabled="!isValid"
          :loading="loading"
        >
          Prüfungsstatus ändern
          <v-icon right dark>
            mdi-checkbox-multiple-marked-circle-outline
          </v-icon>
        </v-btn>
      </template>
    </ui-dialog-card>
  </v-dialog>
</template>

<script>
import { mapActions } from "vuex";

export default {
  props: {
    itemProp: {
      type: Object,
      default: () => {}
    }
  },
  data: () => ({
    states: [
      {
        color: "success",
        icon: "mdi-check-circle-outline",
        text: "Akzeptiert",
        value: "accepted"
      },
      {
        color: "error",
        icon: "mdi-close-circle-outline",
        text: "Abgelehnt",
        value: "declined"
      },
       {
        color: "warning",
        icon: "mdi-minus-circle-outline",
        text: "Bereits vorhanden",
        value: "unnecessary"
      }
    ],
    state: null,

    // functional
    dialog: false,
    isValid: false,
    loading: false
  }),
  computed: {
    uploadRequest() {
      return this.$store.getters["inquiryItem/inquiryItem"]
        .currentUploadRequest;
    },
    rules() {
      return this.$store.getters["application/rules"];
    }
  },
  watch: {
    itemProp(val) {
      this.section = val;
    }
  },
  created() {
    // this.item = this.itemProp ? this.itemProp : this.item;
  },
  methods: {
    ...mapActions({
      showInquiryItem: "inquiryItem/show"
    }),

    changeState() {
      this.loading = true;
      this.axios
        .patch(
          "/upload-requests/" + this.uploadRequest.id + "?state=" + this.state
        )
        .then(() => {
          this.$snotify.success(
            "Der Status des Aufrufs wurde erfolgreich aktualisiert."
          );
          this.showInquiryItem({ id: this.$route.params.id });
          this.close();
        })
        .catch(e => {
          console.log(e);
          this.loading = false;
        });
    },

    // functional
    close() {
      this.dialog = false;
      this.loading = false;
      this.$refs.form.reset();
      this.$refs.form.resetValidation();
      this.$emit("close");
    },

    submit() {
      this.loading = true;
      // function
      this.changeState();
    }
  }
};
</script>

<style scoped></style>
