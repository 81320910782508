<template>

  <div style="width: 100%;" class="mb-8 mt-4">
    
    <v-row>
      <v-col cols="12" xl="8" lg="8" md="8">
        <v-card height="100%">
          <v-card-text class="d-flex justify-center" style="height: 100%;">
            <v-row align="center">
              <v-col cols="12" md="3" sm="3">
                <v-progress-circular
                  :rotate="-90"
                  :color="progressColor ? progressColor : 'primary'"
                  :size="120"
                  :width="15"
                  :value="progressValue"
                >
                  <span class="black--text">
                    {{ Math.round(progressValue) }}%
                  </span>
                </v-progress-circular>
              </v-col>
              <v-col cols="12" md="6" sm="9">
                <div
                  v-if="$hasPermissions('consultant permission')"
                  class="mb-n2"
                >
                  <div class="body-1 mb-2">
                    Erstellt:
                    {{ inquiry.created_at | moment("DD.MM.YYYY HH:mm") }}
                    <span v-if="inquiry.creator"
                      >von {{ inquiry.creator.name_reverse }}</span
                    >
                  </div>
                  <div class="body-1 mb-2" v-if="!inquiry.policy_deleted_at">
                    Letzte Kundenaktivität:
                    <span v-if="inquiry.last_customer_action_at">
                      {{
                        inquiry.last_customer_action_at
                          | moment("DD.MM.YYYY HH:mm")
                      }}
                    </span>
                    <span v-else>
                      Keine
                    </span>
                  </div>
                  <div class="body-1 mb-2" v-else>
                    Gelöscht:
                    {{ inquiry.policy_deleted_at | moment("DD.MM.YYYY HH:mm") }}
                  </div>
                  <div class="body-1 mb-2" v-if="inquiry.contactPerson">
                    Ansprechpartner: {{ inquiry.contactPerson.name }}
                  </div>
                  <v-row align="center" v-if="assignable" class="mt-2">
                    <v-col cols="12" md="auto" sm="auto">
                      <v-avatar class=" white--text" color="primary">
                        <span v-if="!assignable.image_url">{{
                          assignable.initials
                        }}</span>
                        <img
                          v-else
                          :src="assignable.image_url"
                          alt="Profilbild"
                        />
                      </v-avatar>
                    </v-col>
                    <v-col cols="12" md="auto" sm="auto" class="body-1">
                      <div>
                        {{
                          assignable.name_reverse
                            ? assignable.name_reverse
                            : assignable.name
                        }}
                      </div>
                    </v-col>
                  </v-row>
                </div>
                <div v-else>
                  <div v-if="!inquiry.policy_deleted_at">
                    <div class="text-h4 mb-4">
                      {{ progressText.title }}
                    </div>
                    <div class="body-1">
                      {{ progressText.text }}
                    </div>
                  </div>
                  <div v-else>
                    <div class="text-h4 mb-4">
                      Die Inhalte dieser Anfrage wurden gelöscht.
                    </div>
                    <div class="body-1">
                      Gelöscht:
                      {{
                        inquiry.policy_deleted_at | moment("DD.MM.YYYY HH:mm")
                      }}
                    </div>
                  </div>
                </div>
              </v-col>
              <v-col
                cols="12"
                md="3"
                style="height: 100%"
                class="hidden-sm-and-down"
              >
                <div style="position: relative; height: 100%;">
                  <img
                    src="@/assets/inquiries/filling.svg"
                    alt="Frau, Dokumente"
                    style="max-height: calc(100% + 60px); max-width: 100%; position: absolute; bottom: 0; right: 0;"
                  />
                </div>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" xl="4" lg="4" md="4">
        <v-card height="100%">
          <v-subheader>
            {{
              $hasPermissions("consultant permission") ? "Kunde" : "Ihr Berater"
            }}
          </v-subheader>
          <v-card-text>
            <v-row align="center" v-if="detailedUser">
              <v-col cols="12" md="auto" sm="auto">
                <v-avatar class=" white--text" color="primary">
                  <span v-if="!detailedUser.image_url">{{
                    detailedUser.initials
                  }}</span>
                  <img v-else :src="detailedUser.image_url" alt="Profilbild" />
                </v-avatar>
              </v-col>
              <v-col cols="12" md="auto" sm="auto" class="body-1">
                <div>
                  {{ detailedUser.name_reverse }}
                </div>
                <div v-if="detailedUser.label" class="text-caption">
                  Kd Nr.: {{ detailedUser.label }}
                </div>
              </v-col>
            </v-row>

            <span v-else>
              Der Nutzer wurde gelöscht.
            </span>
          </v-card-text>
          <v-card-text>
            {{
              $hasPermissions("consultant permission")
                ? ""
                : "Sie haben eine Frage, oder benötigen Hilfe?"
            }}

            <v-dialog
              v-model="userDetailsDialog"
              max-width="600px"
              v-if="detailedUser"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn block depressed class="mt-4" v-bind="attrs" v-on="on">
                  {{
                    $hasPermissions("consultant permission")
                      ? "Kundendetails"
                      : "Berater kontaktieren"
                  }}
                </v-btn>
              </template>
              <inquiry-consultant-user-details
                :user="
                  $hasPermissions('consultant permission')
                    ? customer
                    : assignable
                "
              />
            </v-dialog>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <div style="width: 100%;" class="mb-4 mt-4">
    <v-row align="end">
      <v-col cols="12" xl="8" lg="8" md="8">
        <div class="text-h3 mb-4">
          {{ inquiry.name }}
        </div>
        <div
          class="body-1 mb-4"
          v-text="inquiry.description"
          v-if="inquiry.description"
        />
           <div
          class="body-1 mb-4"
         
          v-if="inquiry.hint"
        >
         {{
              $hasPermissions("consultant permission") ? inquiry.hint : inquiry.help
            }}
           </div>
      </v-col>
      <v-col cols="12" xl="4" lg="4" md="4" class="pa-8">
        <!--        <img
          src="@/assets/inquiries/filling.svg"
          alt="Anfrage"
          style="height:100%;"
        />-->
      </v-col>
    </v-row>
    <!--    <v-subheader class="mb-4">
      {{ inquiry.created_at }}
    </v-subheader>-->
  </div>
  </div>
</template>
<script>
export default {
  props: {},
  data: () => ({
    userDetailsDialog: false
  }),
  computed: {
    assignable() {
      return this.$store.getters["inquiry/currentInquiry"].assignable;
    },
    contactPerson() {
      return this.$store.getters["inquiry/currentInquiry"].contactPerson;
    },
    customer() {
      return this.$store.getters["inquiry/currentInquiry"].customer;
    },

    detailedUser() {
      return this.$hasPermissions("consultant permission")
        ? this.customer
        : this.contactPerson
        ? this.contactPerson
        : this.assignable;
    },

    inquiry() {
      return this.$store.getters["inquiry/currentInquiry"];
    },

    /*calculation*/
    progressColor() {
      if (this.inquiry) {
        let ratio = this.inquiry.fulfillment_ratio;
        let val = "";
        if (ratio >= 0.1) {
          val = "primary lighten-2";
        }
        if (ratio >= 0.35) {
          val = "primary lighten-1";
        }
        if (ratio >= 0.5) {
          val = "primary";
        }
        if (ratio >= 0.6) {
          val = "primary darken-1";
        }
        if (ratio >= 0.7) {
          val = "primary darken-2";
        }
        if (ratio >= 0.8) {
          val = "primary darken-3";
        }
        if (ratio >= 0.9) {
          val = "primary darken-4";
        }

        return val;
      } else {
        return "primary lighten-4";
      }
    },

    progressValue() {
      return this.inquiry ? this.inquiry.fulfillment_ratio * 100 : 0;
    },

    progressText() {
      if (this.inquiry) {
        let ratio = this.inquiry.fulfillment_ratio;
        let val = {
          title: "Legen Sie los!",
          text:
            "Laden Sie ganz bequem Ihre Dokumente hoch und geben sie diese Ihrem Berater frei."
        };
        if (ratio >= 0.1) {
          val = {
            title: "Der erste Schritt ist getan!",
            text:
              "Laden Sie ganz einfach Ihre Dokumente hoch und geben sie diese ihrem Berater frei."
          };
        }
        if (ratio >= 0.2) {
          val = {
            title: "Das läuft doch super!",
            text:
              "Ihre hochgeladenen Unterlagen werden von Ihrem Berater geprüft. Laden Sie fehlende Unterlagen über die Übersicht hoch."
          };
        }
        if (ratio >= 0.5) {
          val = {
            title: "Über die Hälfte ist geschafft!",
            text:
              "Wie hat Ihnen der Weg bis hier hin gefallen? Laden Sie fehlende Unterlagen über die Übersicht hoch."
          };
        }
        if (ratio >= 0.8) {
          val = {
            title: "Fast geschafft!",
            text: "Jetzt fehlen nur noch wenige Schritte zum Abschluss!"
          };
        }

        if (ratio >= 1) {
          val = {
            title: "Alles erledigt!",
            text:
              "Sollte die Prüfung Ihrer Unterlagen erfolgreich sein, wird Ihr Berater mit Ihnen Kontakt aufnehmen und das weitere Vorgehen besprechen."
          };
        }
        return val;
      } else {
        return {
          title: "",
          text: ""
        };
      }
    }
  },
  watch: {},
  created() {},
  methods: {}
};
</script>
<style scoped></style>
