<template>
  <div>
    <v-card-title>
      <span class="headline">
        {{ isWithMail ? "Freigeben mit E-Mail" : "Freigeben ohne E-Mail" }}
      </span>
    </v-card-title>
    <v-card-text>
      {{
        isWithMail
          ? "Sind Sie sich sicher, dass Sie diesen Abschnitt mit E-Mail freigeben wollen?"
          : "Sind Sie sich sicher, dass Sie diesen Abschnitt ohne E-Mail freigeben wollen?"
      }}
    </v-card-text>
  </div>
</template>

<script>
export default {
  props: {
    isWithMail: Boolean
  },
  data: () => ({}),
  computed: {},
  watch: {},
  created() {},
  methods: {}
};
</script>

<style scoped></style>