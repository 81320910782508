import Vue from "vue";
import Vuex from "vuex";
import authentication from './authentication'
import inquiry from "./inquiry";
import application from "./application";
import inquiryItem from "./inquiryItem";
import company from "./company";
Vue.use(Vuex);
export default new Vuex.Store({
    state: {},
    actions: {},
    mutations: {},
    modules: {
     application,
     authentication,
     inquiry,
     inquiryItem,
     company
    
    }
});