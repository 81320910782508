<template>
  <v-hover v-slot="{ hover }" style="height: 100%">
    <router-link
      :to="{ name: 'Inquiries' }"
      class="custom-link"
      style="height: 100%"
    >
      <v-card
        :elevation="hover ? 12 : 2"
        class="transition-ease-in-out d-flex flex-column justify-space-between align-content-center"
        height="100%"
      >
        <div class="px-6 py-6">
          <v-img
            src="@/assets/dashboard/inquiries.svg"
            max-width="100%"
            max-height="240px"
            contain
          />
        </div>
        <v-card-actions class="px-6 py-4">
          <v-btn block color="primary" :to="{ name: 'Inquiries' }">
            Zu Ihren Anfragen

            <v-icon right dark>
              mdi-arrow-right
            </v-icon>
          </v-btn>
        </v-card-actions>
      </v-card>
    </router-link>
  </v-hover>
</template>
<script>
export default {
  props: {},
  data: () => ({}),
  computed: {},
  watch: {},
  created() {},
  methods: {}
};
</script>

<style scoped></style>
