<template>
  <div>
    <v-row justify="space-between" class="flex-nowrap">
      <v-col cols="12" md="11">
        <v-chip
          label
          class="mb-4"
          small
          color="warning"
          v-if="milestoneProp.milestone_progress < 1"
        >
          Noch nicht erreicht ({{ Math.round(progressValue) }}%)
        </v-chip>
        <v-chip
          label
          dark
          class="mb-4"
          small
          color="success"
          v-else-if="
            milestoneProp.is_autorelease ||
              milestoneProp.release_state === 'success'
          "
        >
          <v-icon left small>mdi-check-circle</v-icon>
          Zwischenziel erreicht
        </v-chip>
        <v-chip
          label
          dark
          class="mb-4"
          small
          color="success"
          v-else-if="!milestoneProp.is_autorelease"
        >
          <v-icon left small>mdi-check-circle</v-icon>
          Alle Anforderungen erfüllt - nun sind wir dran!
        </v-chip>
        <div
          class="text-h5 mb-4"
          v-if="milestoneProp.name"
          v-html="milestoneProp.name"
        />
        <div
          class="body-1 mb-4"
          v-if="milestoneProp.description"
          v-html="milestoneProp.description"
        />

        <div class="body-1 mt-4 grey--text text--darken-2 font-weight-light">
          <span v-if="milestoneProp.milestone_progress < 1">
            Für das Erreichen dieses Zwischenziels werden noch weitere
            Unterlagen benötigt
          </span>
          <span
            v-else-if="
              milestoneProp.is_autorelease ||
                milestoneProp.release_state === 'success'
            "
          >
            Sie haben erfolgreich alle Anforderungen für das Erreichen des
            Zwischenziels erfüllt.
          </span>
          <span v-else-if="!milestoneProp.is_autorelease">
            Sie haben erfolgreich alle Anforderungen für das Erreichen des
            Zwischenziels erfüllt.<br />
            Wir bearbeiten nun Ihr Anliegen.
          </span>
        </div>
      </v-col>
      <v-col
        cols="12"
        md="auto"
        v-if="
          ($hasPermissions('consultant permission') &&
            !isPreview &&
            !isShare) ||
            (isShare && sharePermission === 'write')
        "
      >
        <v-menu offset-y bottom left transition="slide-y-transition">
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon v-bind="attrs" v-on="on">
              <v-icon>mdi-dots-vertical</v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-dialog
              max-width="600"
              persistent
              v-model="milestoneProp.releaseDialog1"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-list-item
                  link
                  v-bind="attrs"
                  v-on="on"
                  :disabled="milestoneProp.release_state === 'success'"
                >
                  Als erfüllt kennzeichnen
                </v-list-item>
              </template>
              <v-card>
                <v-card-title>
                  <span class="headline">
                    Zwischenziel als erfüllt kennzeichnen
                  </span>
                </v-card-title>
                <v-card-text>
                  Kennzeichnen Sie ein Zwischenziel als erfüllt, um dem Kunden
                  mitzuteilen, dass er diesen Bereich der Anfrage erfolgreich
                  bearbeitet hat.
                </v-card-text>
                <v-card-text class="d-flex justify-end mt-4">
                  <v-btn text @click="milestoneProp.releaseDialog1 = false">
                    Abbrechen
                  </v-btn>
                  <v-btn
                    class="ml-2"
                    color="primary"
                    dark
                    @click="
                      milestoneProp.releaseDialog1 = false;
                      release('success');
                    "
                  >
                    Als erfüllt kennzeichnen
                    <v-icon right>
                      mdi-check-circle
                    </v-icon>
                  </v-btn>
                </v-card-text>
              </v-card>
            </v-dialog>
            <!--            <v-dialog
              max-width="600"
              persistent
              v-model="milestoneProp.releaseDialog"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-list-item
                  link
                  v-bind="attrs"
                  v-on="on"
                  :disabled="milestoneProp.release_state !== null"
                >
                  Freigeben mit E-Mail
                </v-list-item>
              </template>
              <v-card>
                <inquiries-show-timeline-section-release :isWithMail="true" />
                <v-card-text class="d-flex justify-end mt-4">
                  <v-btn text @click="milestoneProp.releaseDialog = false">
                    Abbrechen
                  </v-btn>
                  <v-btn
                    class="ml-2"
                    color="primary"
                    dark
                    @click="
                      milestoneProp.releaseDialog = false;
                      release('released_with_email');
                    "
                  >
                    Freigeben mit E-Mail
                    <v-icon right>
                      mdi-lock-open-outline
                    </v-icon>
                  </v-btn>
                </v-card-text>
              </v-card>
            </v-dialog>-->

            <!--TODO: clean-->
          </v-list>
        </v-menu>
      </v-col>
    </v-row>

    <div></div>
  </div>
</template>

<script>
export default {
  props: {
    milestoneProp: Object,
    isPreview: {
      type: Boolean,
      default: false
    },
    isShare: {
      type: Boolean,
      default: false
    },
    sharePermission: {
      type: String,
      default: "read"
    }
  },
  data: () => ({}),
  computed: {
    progressValue() {
      return this.milestoneProp
        ? this.milestoneProp.milestone_progress * 100
        : 0;
    }
  },
  watch: {},
  created() {},
  methods: {
    release(release_state) {
      this.axios
        .patch(
          "/inquiry-items/release/" +
            this.milestoneProp.id +
            "/" +
            release_state
        )
        .then(() => {
          this.$snotify.success("Der Abschnitt wurde erfolgreich freigegeben.");
          this.milestoneProp.release_state = release_state;
        })
        .catch(e => {
          console.log(e);
          this.$snotify.error(
            "Bei der Freigabe des Abschnitts ist ein Fehler aufgetreten."
          );
        });
    }
  }
};
</script>

<style scoped></style>
