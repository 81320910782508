<template>
  <div>
    <div class="text-overline">
      Erweitert
    </div>
    <div class="mb-4">
      <div class="text-h6">
        Alles herunterladen
      </div>

      <div class="body-1">
        Sie können alle in dieser Anfrage hochgeladenen und freigegebenen
        Dokumente herunterladen.
      </div>
      <v-btn
        depressed
        class="mt-2"
        :loading="downloadLoading"
        @click="downloadAll"
        >Alles herunterladen
      </v-btn>
    </div>
    <div class="mb-4">
      <div class="text-h6">
        Löschen
      </div>
      <div class="body-1">
        Beim Löschen wird der Inhalt der Anfrage samt hochgeladener Unterlagen
        gelöscht. Die Anfrage wird in einen Gelöscht-Zustand versetzt und kann
        dann entgültig archiviert werden. <br />
        Zudem werden bestehende Zugriffsberechtigungen gelöscht.
      </div>

      <v-dialog v-model="deleteDialog" persistent max-width="500px">
        <template v-slot:activator="{ on, attrs }">
          <v-btn depressed v-bind="attrs" v-on="on" class="mt-2">
            Löschen
            <v-icon color="error" right>mdi-delete</v-icon>
          </v-btn>
        </template>
        <v-card>
          <v-card-title class="headline">
            Wollen Sie diese Anfrage wirklich löschen?
          </v-card-title>
          <v-card-text>
            Beim Löschen wird der Inhalt der Anfrage samt hochgeladener
            Unterlagen gelöscht. Die Anfrage wird in einen Gelöscht-Zustand
            versetzt und kann dann entgültig archiviert werden. <br />
            Zudem werden bestehende Zugriffsberechtigungen gelöscht.
          </v-card-text>
          <v-card-actions class="px-4">
            <v-btn depressed :loading="downloadLoading" @click="downloadAll"
              >Alles herunterladen
            </v-btn>
            <v-spacer />
            <v-btn text @click="deleteDialog = false">
              Abbrechen
            </v-btn>
            <v-btn color="error" class="ml-2" @click="policyDeleteInquiry()">
              Löschen
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
  </div>
</template>

<script>
export default {
  props: {},
  data: () => ({
    downloadLoading: false,

    deleteLoading: false,
    deleteDialog: false
  }),
  computed: {
    inquiry() {
      return this.$store.getters["inquiry/currentInquiry"];
    },
    customer() {
      return this.$store.getters["inquiry/currentInquiry"].customer;
    },
    rules() {
      return this.$store.getters["application/rules"];
    }
  },
  watch: {},
  created() {},
  methods: {
    policyDeleteInquiry() {
      this.deleteLoading = true;
      this.axios
        .delete("/inquiries/" + this.$route.params.id + "/policy-delete")
        .then(() => {
          this.$router.push({
            name: "InquiriesShow",
            params: { id: this.$route.params.id }
          });
          this.$snotify.success("Die Anfrage wurde erfolgreich gelöscht.");
        })
        .catch(e => {
          console.log(e);
          this.$snotify.error(
            "Beim Löschen der Anfrage ist ein Fehler aufgetreten."
          );
        })
        .finally(() => {
          this.deleteLoading = true;
          this.deleteDialog = false;
        });
    },
    downloadAll() {
      this.downloadLoading = true;
      this.axios
        .get("/inquiries/" + this.inquiry.id + "/download-all", {
          responseType: "blob"
        })
        .then(r => {
          console.log(r.data);
          const url = URL.createObjectURL(new Blob([r.data]));

          let fileName = this.inquiry.nameSlug + ".zip";
          if (this.customer && this.customer.label) {
            fileName =
              this.customer.label + "_" + this.inquiry.nameSlug + ".zip";
          }

          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", fileName);
          document.body.appendChild(link);
          link.click();
        })
        .catch(() => {
          this.$snotify.info(
            "Die Anfrage enthält keine hochgeladenen Unterlagen."
          );
        })
        .finally(() => {
          this.downloadLoading = false;
        });
    }
  }
};
</script>

<style scoped></style>
