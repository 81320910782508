<template>
  <div
    class="d-flex justify-center align-center flex-column"
    style="width: 100%"
  >
    <div class="text-h2">Willkommen bei fileCQ!</div>
    <v-btn class="primary mt-4" x-large :to="{ name: 'Login' }">
      Login
      <v-icon right color="white">
        mdi-login
      </v-icon>
    </v-btn>
  </div>
</template>

<script>
export default {
  name: "Home",
  created() {
    this.$router.push({ name: "Login" });
  }
};
</script>

