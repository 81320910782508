<template>
  <div>
    <v-row justify="center">
      <v-col cols="12" xl="7" lg="9">
        <v-data-iterator
          :items="items"
          :search="search"
          :sort-by="sortBy"
          :sort-desc="sortDesc"
          class="mt-4"
        >
          <template v-slot:default="props">
            <div v-if="!isShare">
              <div
                v-for="inquiry in props.items"
                :key="inquiry.id"
                class="mt-4"
              >
                <util-inquiry-card
                  :inquiryProp="inquiry"
                  :sortBy="sortBy"
                  :isDraft="isDraft"
                />
              </div>
            </div>
            <div v-else>
              <div v-for="share in props.items" :key="share.id" class="mt-4">
                <util-inquiry-card
                  :inquiryProp="share.shareable"
                  v-if="share.shareable_type === 'App\\Models\\Inquiry'"
                  :isShare="true"
                  :share="share"
                />
                <util-inquiry-item-card
                  :inquiryItemProp="share.shareable"
                  v-if="share.shareable_type === 'App\\Models\\InquiryItem'"
                  :isShare="true"
                  :share="share"
                />
              </div>
            </div>
          </template>
        </v-data-iterator>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  props: {
    sortDesc: Boolean,
    sortBy: String,
    search: String,
    isShare: Boolean,
    isDraft: Boolean
  },
  data: () => ({
    loading: false,
    isValid: false
  }),
  computed: {
    inquiryDashboardIndex() {
      return this.$store.getters["inquiry/index"];
    },
    userDetails() {
      return this.$store.getters["authentication/userDetails"];
    },
    items() {
      switch (this.$route.query.type) {
        case "sharedWith":
          return this.inquiryDashboardIndex.sharedWithUser;
        case "assignedToTeams":
          return this.inquiryDashboardIndex.assignedToTeams;
        case "sharedWithTeams":
          return this.inquiryDashboardIndex.sharedWithTeam;
        case "drafts":
          return this.inquiryDashboardIndex.assignedInquiries_draft;
      }
      return this.inquiryDashboardIndex.assignedInquiries;
    }
  },
  created() {},
  methods: {}
};
</script>

<style scoped></style>
