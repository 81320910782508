<template>
  <div>
    <v-card v-if="uploadRequest">
      <v-list-item two-line class="accent lighten-5">
        <v-list-item-action>
          <v-menu
            top
            offset-y
            offset-x
            open-on-hover
            transition="slide-x-transition"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-avatar
                dark
                size="35"
                :color="statusColor"
                v-bind="attrs"
                v-on="on"
              >
                <v-icon size="25" dark>{{ statusIcon }}</v-icon>
              </v-avatar>
            </template>
            <v-card max-width="500px">
              <v-card-actions class="pb-0">
                <v-btn text :color="statusColor" style="pointer-events: none;">
                  {{ statusTitle }}

                  <v-icon right :color="statusColor">
                    {{ statusIcon }}
                  </v-icon>
                </v-btn>
              </v-card-actions>
              <v-card-text class="pt-0">
                {{ statusText }}
              </v-card-text>
            </v-card>
          </v-menu>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title class="text-h6">
            Aufruf vom
            {{ uploadRequest.created_at | moment("DD.MM.YYYY HH:mm") }}
          </v-list-item-title>
          <v-list-item-subtitle v-if="uploadRequest.help">
            {{ uploadRequest.help }}
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
      <ui-primary-divider />
      <div
        v-if="
          $hasPermissions('consultant permission') ||
            (uploadRequest.uploadsIntern &&
              uploadRequest.uploadsIntern.length > 0)
        "
      >
        <v-subheader>
          {{
            $hasPermissions("consultant permission")
              ? "Intern bereitgestellt"
              : "Bereitgestellte Unterlagen Ihrer Bank"
          }}
        </v-subheader>
        <v-divider />
        <div
          v-if="
            uploadRequest.uploadsIntern &&
              uploadRequest.uploadsIntern.length > 0
          "
        >
          <template v-for="upload in uploadRequest.uploadsIntern">
            <inquiry-items-upload-requests-upload
              :upload-prop="upload"
              :upload-request-state="uploadRequest.state"
              :key="upload.id"
            />
          </template>
        </div>
        <v-card-text v-else>
          Es wurde nichts hochgeladen / freigegeben.
        </v-card-text>
      </div>

      <div>
        <v-subheader>
          {{
            $hasPermissions("consultant permission")
              ? "Durch Kunden bereitgestellt"
              : "Ihre hochgeladenen Unterlagen"
          }}
        </v-subheader>
        <v-divider />
        <div
          v-if="
            uploadRequest.uploadsExtern &&
              uploadRequest.uploadsExtern.length > 0
          "
        >
          <template v-for="upload in uploadRequest.uploadsExtern">
            <inquiry-items-upload-requests-upload
              :upload-prop="upload"
              :upload-request-state="uploadRequest.state"
              :key="upload.id"
            />
          </template>
        </div>
        <v-card-text v-else>
          <span v-if="$hasPermissions('consultant permission')"
            >Es wurde nichts hochgeladen / freigegeben.
          </span>

          <v-alert
            dense
            text
            v-else-if="uploadRequest.state === null"
            type="warning"
            class="mb-0"
          >
            Sie haben noch keine Unterlagen eingereicht.
          </v-alert>
        </v-card-text>
      </div>
      <v-card-actions class="pa-4">
        <div style="width: 100%;">
          <div v-if="$hasPermissions('consultant permission')">
            <inquiry-items-upload-requests-download-all
              :upload-request="uploadRequest"
            />
          </div>
        </div>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
export default {
  props: {
    uploadRequest: {
      type: Object,
      default: () => {}
    }
  },
  data: () => ({}),
  computed: {
    statusColor() {
      switch (this.uploadRequest.state) {
        case null:
          return "warning";
        case "received":
          return "info";
        case "accepted":
          return "success";
        case "declined":
          return "error";
      }
      return "warning";
    },
    statusIcon() {
      switch (this.uploadRequest.state) {
        case null:
          return "mdi-checkbox-blank-circle-outline";
        case "received":
          return "mdi-clock-time-five-outline";
        case "accepted":
          return "mdi-check-circle-outline";
        case "declined":
          return "mdi-close-circle-outline";
      }
      return "";
    },
    statusTitle() {
      switch (this.uploadRequest.state) {
        case null:
          return "Ist hochzuladen";
        case "received":
          return "In Prüfung";
        case "accepted":
          return "Akzeptiert";
        case "declined":
          return "Abgelehnt";
      }
      return "";
    },
    statusText() {
      switch (this.uploadRequest.state) {
        case null:
          return (
            "Bitte laden Sie die entsprechenden Unterlagen hoch und " +
            "geben Sie diese Ihrer Bank frei."
          );
        case "received":
          return (
            "Ihre Unterlagen werden nun durch Ihre Bank geprüft. " +
            "Sobald die Prüfung fertiggestellt wurde, ändert sich entsprechend der Status."
          );
        case "accepted":
          return (
            "Die Prüfung Ihrer Unterlagen war erfolgreich. " +
            "Sie können den aktuellen Fortschritt der Anfrage immer in der Übersicht einsehen."
          );
        case "declined":
          return (
            "Leider war die Prüfung Ihrer Unterlagen nicht " +
            "erfolgreich. Sollte es entsprechenden Bedarf geben, können Sie über einen Folgeaufruf zur Einreichung die korrekten Unterlagen hochladen."
          );
      }
      return "";
    }
  },
  watch: {},
  created() {},
  methods: {}
};
</script>

<style scoped></style>
