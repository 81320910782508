<template>
  <v-row justify="center">
    <v-col cols="12" xl="6" lg="8">
      <div v-if="inquiry.is_draft" class="mb-2">
        <ui-chips-draft />
      </div>
      <div class="text-h4 mb-8">
        {{ inquiry.name }}
      </div>
      <v-tabs v-if="inquiry.id" v-model="tabs" background-color="white">
        <v-tab
          :to="{ name: getRoute('form'), params: { id: inquiry.id } }"
          link
        >
          Allgemein
        </v-tab>
        <v-tab
          :to="{ name: getRoute('layout'), params: { id: inquiry.id } }"
          link
        >
          Aufbau
        </v-tab>
        <v-tab
          :to="{ name: getRoute('preview'), params: { id: inquiry.id } }"
          link
        >
          Vorschau
        </v-tab>
      </v-tabs>
    </v-col>
  </v-row>
</template>

<script>
export default {
  props: {
    isTemplate: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    tabs: 0
  }),
  computed: {
    inquiry() {
      return this.$store.getters["inquiry/currentInquiry"];
    }
  },
  watch: {},
  created() {},
  methods: {
    /*type: form, layout, preview*/
    getRoute(type) {
      if (this.isTemplate) {
        switch (type) {
          case "form":
            return "SettingsInquiriesEditGeneral";
          case "layout":
            return "SettingsInquiriesEditLayout";
          case "preview":
            return "SettingsInquiriesEditPreview";
        }
      } else {
        switch (type) {
          case "form":
            return "InquiriesEditGeneral";
          case "layout":
            return "InquiriesEditLayout";
          case "preview":
            return "InquiriesEditPreview";
        }
      }
    }
  }
};
</script>

<style scoped></style>
