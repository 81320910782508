<template>
  <div>
    <dashboard-consultant-progress-template
      title="Fertiggestellte Anfragen"
      :progress-value="
        Math.round(
          (inquiryDashboardIndex.finished_count /
            inquiryDashboardIndex.total_count) *
            100
        )
      "
      :progress-text="inquiryDashboardIndex.finished_count"
    >
    </dashboard-consultant-progress-template>
  </div>
</template>

<script>
export default {
  props: {},
  data: () => ({}),
  computed: {
    inquiryDashboardIndex() {
      return this.$store.getters["inquiry/index"];
    }
  },
  watch: {},
  created() {},
  methods: {}
};
</script>

<style scoped></style>
