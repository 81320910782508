<template>
  <v-app>
    <v-row justify="center">
      <v-col cols="12" xl="9" lg="11">
        <v-row>
          <v-col cols="12" md="12">
            <v-card flat color="transparent">
              <v-row no-gutters>
                <v-col cols="12" md="8" class="pr-10 py-6">
                  <div class="text-h3">
                    Willkommen bei fileCQ!
                  </div>
                </v-col>
              </v-row>
            </v-card>
            <v-card flat color="accent">
              <v-row no-gutters>
                <v-col cols="12" md="8" class="pr-10 py-6">
                  <v-card-title class="text-h4">
                     
                  <dashboard-greeting />  
                  </v-card-title>
                 <v-card-text class="text-h5">
                    <span v-if="$hasPermissions('consultant permission')"
                      >Bereit ein paar Anfragen aufzunehmen?</span
                    >
                    <span v-else-if="$hasPermissions('customer permission')"
                      >Wie geht es Ihnen heute?</span
                    > 
                     <div
                      class="mt-4"
                        v-if="$hasPermissions('consultant permission')"
                    >
                      <inquiry-common-create-dialog use-type="dashboard" />
                    </div> 
                  </v-card-text> 
                </v-col>
               <v-col cols="12" md="4">
                  <div style="position: relative; height: 100%;">
                    <img
                      src="@/assets/dashboard/header.svg"
                      alt="Frau, Dokumente"
                      style="height: calc(100% + 60px);position: absolute; bottom: 0; right: 0;"
                    />
                  </div>
                </v-col> 
              </v-row>
            </v-card>
          </v-col>
        </v-row>
        <div class="text-overline mb-2 mt-4">
          Ihre Anfragen
        </div>
        <v-row>
          <v-col cols="12" lg="4" md="4">
            <dashboard-inquiries-card />
          </v-col>
          <v-col cols="12" lg="8" md="8">
            <div
               v-if="$hasPermissions('consultant permission')"
              style="height: 100%"
            >
              <dashboard-consultant-progress style="height: 100%" />
            </div>
             <div v-else>
              <dashboard-customer-stats style="height: 100%" />
            </div> 
          </v-col> 
        </v-row> 

         <dashboard-consultant-stats
          v-if="$hasPermissions('consultant permission')"
          class="mt-10"
        /> 
      </v-col>
    </v-row>
  </v-app>
</template>
<script>
import { mapActions } from "vuex";

export default {
  props: {},
  data: () => ({}),
  computed: {},
  watch: {},
  created() {
   this.index();
  
  },
  methods: {
    ...mapActions({
      index: "inquiry/index"
    }),
    
  }
};
</script>

<style scoped></style>
