import axios from "axios";
import store from "../store";
export default {

  index(ctx) {
    return new Promise((resolve, reject) => {
      axios
        .get("/dashboard/inquiries")
        .then(response => {
          ctx.commit("setIndex", response.data);
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  showInquiry(ctx, id) {
    const userPermissions = store.getters["authentication/permissions"];
    var canEnter = userPermissions.includes("consultant permission");
  //  console.log(userPermissions);
    return new Promise((resolve, reject) => {
      axios
        .get("/inquiries/" + id)
        .then(response => {
          ctx.commit("setCurrentInquiry", response.data.inquiry);

          if(canEnter){
            ctx.dispatch("indexAvailableDocuments");
          }
          resolve(response);
          
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  indexAvailableDocuments(ctx) {
    return new Promise((resolve, reject) => {
      let id = ctx.getters["currentInquiry"].id;
      axios
        .get("/documents?tree=true&inquiry=" + id)
        .then(response => {
          ctx.commit("setAvailableDocuments", response.data.documents);
          resolve(response);
        })
        .catch(error => {
         reject(error);
        });
    });
  },
  store(ctx, { payload }) {
    return new Promise((resolve, reject) => {
      axios
        .post("/inquiries", {
          inquiry: payload
        })
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  patch(ctx, { id, payload, onlySections, finish }) {
    const urlAppend = onlySections
      ? "?onlySections=true"
      : finish
      ? "?finish=true"
      : "";
    return new Promise((resolve, reject) => {
      axios
        .patch("/inquiries/" + id + urlAppend, {
          inquiry: payload
        })
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  destroy(ctx, id) {
    return new Promise((resolve, reject) => {
      axios
        .delete("/inquiries/" + id)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  }
};
