import { render, staticRenderFns } from "./open-progress.vue?vue&type=template&id=586883e3&scoped=true&"
import script from "./open-progress.vue?vue&type=script&lang=js&"
export * from "./open-progress.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "586883e3",
  null
  
)

export default component.exports