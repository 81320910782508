import AuthService from "@/services/authentication.service.js";

export default {
    forgotPassword(ctx, payload) {
        AuthService.forgotPassword(ctx, payload);
    },
    resetPassword(ctx, payload) {
       return AuthService.resetPassword(ctx, payload);
    },
    login(ctx, payload) {
       return AuthService.login(ctx,payload);
    },

    userState(ctx) {
       return AuthService.userState(ctx);
    },

    logout(ctx) {
        return AuthService.logout(ctx);
    },
    state(ctx){
        return AuthService.state(ctx);
    }
    


}