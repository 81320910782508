export default {
    index(state) {
      return state.index;
    },
    currentInquiry(state) {
        return state.currentInquiry;
    },
    availableDocuments(state) {
        return state.availableDocuments;
    }
  };
  