import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Login from "@/views/authentication/Login";
import ForgotPassword from "@/views/authentication/ForgotPassword";
import ResetPassword from "@/views/authentication/ResetPassword";
import Dashboard from "@/views/dashboard/Dashboard";
import Middleware from "../middleware/index";
import MainWrapper from "../views/authentication/Wrapper";
import PassThrough from "@/views/PassThrough";
import PassThroughMain from "@/views/PassThroughMain";
Vue.use(VueRouter)


const routes = [
  {
    path: "",
    component: MainWrapper,
    children: [
      {
        path: "",
        name: "Home",
        components: {
          main: Home
        },
        meta: {
          nav: false,
          middleware: [Middleware.guest]
        }
      },

      {
        path: "/login",
        name: "Login",
        components: {
          main: Login
        },
        meta: {
          nav: false,
          middleware: [Middleware.guest]
        }
      },
      {
        path: "/password-vergessen",
        name: "ForgotPassword",
        components: {
          main: ForgotPassword,

        },
        meta: {
          nav: false,
          middleware: [Middleware.guest]
        }
      },
      {
        path: "/passwort-zurucksetzen",
        name: "ResetPassword",
        components: {
          main: ResetPassword,

        },
        meta: {
          nav: false,
          middleware: [Middleware.guest]
        }
      }
    ]
  },
  {
    path: "",
    name: "Main",

    component: () =>
      import(/* webpackChunkName: "auth" */ "../views/main/Main"),
    children: [{
      path: "/dashboard",
      name: "Dashboard",
      components: {
        main: Dashboard
      },
      meta: {
        barTitle: "Dashboard",
        middleware: [Middleware.auth]
      }
    },
    {
      path: "/anfragen",
      name: "Inquiries",
      components: {
        main: () =>
          import(
            "../views/inquiries/Index"
          )
      },
      meta: {
        barTitle: "Anfragen",
        middleware: [Middleware.auth]
      }
    },
    {
      path: "/anfragen/zeigen/:id",
      name: "InquiriesShow",
      components: {
        main: () =>
          import(
            /* webpackChunkName: "auth" */ "../views/inquiries/Show"
          )
      },
      meta: {
        barTitle: "Anfragen anzeigen",
        middleware: [Middleware.auth]
      }
    },
    {
      path: "/anfragen-items/zeigen/:id",
      name: "InquiryItemsShow",
      components: {
        main: () =>
          import(
            /* webpackChunkName: "auth" */ "../views/inquiries/items/Show"
          )
      },
      meta: {
        barTitle: "Anforderung anzeigen",
        middleware: [Middleware.auth]
      }
    },
    {
      path: "/anfragen-items-shares/zeigen/:id",
      name: "InquiryItemsSharesShow",
      components: {
        main: () =>
          import(
            /* webpackChunkName: "auth" */ "../views/shares/InquirySectionDocuments"
          )
      },
      meta: {
        barTitle: "Anforderung anzeigen",
        middleware: [Middleware.auth]
      }
    },
    {
      path: "/anfragen/form/:id",
      name: "InquiriesEdit",
      components: {
        main: () =>
          import(
            /* webpackChunkName: "auth" */ "../views/inquiries/Form"
          )
      },
      meta: {
        barTitle: "Anfrage bearbeiten",
        middleware: [Middleware.auth]
      },
      children: [
        {
          path: "general",
          name: "InquiriesEditGeneral",
          component: () =>
            import(
              /* webpackChunkName: "auth" */ "../views/inquiries/General"
            ),
          meta: {
            barTitle: "Allgemein - Anfragen bearbeiten",
            middleware: [Middleware.auth],
            permissions: ["consultant permission"]
          }
        },
        {
          path: "layout",
          name: "InquiriesEditLayout",
          component: () =>
            import(
              /* webpackChunkName: "auth" */ "../views/inquiries/Layout"
            ),
          meta: {
            barTitle: "Layout - Anfragen bearbeiten",
            middleware: [Middleware.auth],
            permissions: ["consultant permission"]
          }
        },
        {
          path: "preview",
          name: "InquiriesEditPreview",
          component: () =>
            import(
              /* webpackChunkName: "auth" */ "../views/inquiries/Preview"
            ),
          meta: {
            barTitle: "Vorschau - Anfrage bearbeiten",
            middleware: [Middleware.auth],
            permissions: ["consultant permission"]
          }
        }
      ]
    },
    {
      path: "/einstellungen",
      name: "Settings",
      components: {
        main: PassThrough
      },
      children: [
        {
          path: "customer-types",
          component: PassThroughMain,
          meta: {
            middleware: [Middleware.auth],
            permissions: ["technical-admin permission"]
          },
          children: [
            {
              path: "/anfragen/vorlage",
              name: "SettingsInquiries",
              components: {
                main: () =>
                  import(
          /* webpackChunkName: "auth" */ "../views/inquiries/templates/Index"
                  )
              },
              meta: {
                barTitle: "Anfragenvorlagen",
                middleware: [Middleware.auth],
                permissions: ["subject-admin permission"]
              }
            },
            {
              path: "form/:id",
              name: "SettingsInquiriesEdit",
              components: {
                main: () =>
                  import(
            /* webpackChunkName: "auth" */ "../views/inquiries/templates/Form"
                  )
              },
              meta: {
                barTitle: "Anfragenvorlage bearbeiten",
                middleware: [Middleware.auth],
                permissions: ["subject-admin permission"]
              },
              children: [
                {
                  path: "general",
                  name: "SettingsInquiriesEditGeneral",
                  component: () =>
                    import(
              /* webpackChunkName: "auth" */ "../views/inquiries/templates/Form/General"
                    ),
                  meta: {
                    barTitle: "Allgemein - Anfragenvorlage bearbeiten",
                    middleware: [Middleware.auth],
                    permissions: ["subject-admin permission"]
                  }
                },
                {
                  path: "layout",
                  name: "SettingsInquiriesEditLayout",
                  component: () =>
                    import(
              /* webpackChunkName: "auth" */ "../views/inquiries/templates/Form/Layout"
                    ),
                  meta: {
                    barTitle: "Layout - Anfragenvorlage bearbeiten",
                    middleware: [Middleware.auth],
                    permissions: ["subject-admin permission"]
                  }
                },
                {
                  path: "preview",
                  name: "SettingsInquiriesEditPreview",
                  component: () =>
                    import(
              /* webpackChunkName: "auth" */ "../views/inquiries/templates/Form/Preview"
                    ),
                  meta: {
                    barTitle: "Vorschau - Anfragenvorlage bearbeiten",
                    middleware: [Middleware.auth],
                    permissions: ["subject-admin permission"]
                  }
                }
              ]
            },
            {
              path: "/dokument",
              name: "Documents",
              components: {
                main: () =>
                  import(
          /* webpackChunkName: "auth" */ "../views/documents/Documents"
                  )
              },
              meta: {
                barTitle: "Dokumente",
                middleware: [Middleware.auth],
                permissions: ["subject-admin permission"]
              }

            },
            {
              path: "dokument/:id",
              name: "DocumentEdit",
              components: {
                main: () =>
                  import(
                      /* webpackChunkName: "auth" */ "../views/documents/DocumentDetails"
                  )
              },
              meta: {
                barTitle: "Dokument bearbeiten",
                middleware: [Middleware.auth],
                permissions: ["subject-admin permission"]
              }
            },
            {
              path: "dokument/neu",
              name: "DocumentCreate",
              components: {
                main: () =>
                  import(
                    "../views/documents/DocumentDetails"
                  )
              },
              meta: {
                barTitle: "Dokument erstellen",
                middleware: [Middleware.auth],
                permissions: ["subject-admin permission"]
              }
            },
            {
              path: "/dokumententypen",
              name: "DocumentTypes",
              components: {
                main: () =>
                  import(
                    "../views/documents/types/DocumentTypes"
                  )
              },
              meta: {
                barTitle: "Dokumententypen",
                middleware: [Middleware.auth],
                permissions: ["subject-admin permission"]
              }
            },
            {
              path: "dokumententypen/neu",
              name: "DocumentTypeCreate",
              components: {
                main: () =>
                  import(
                    "../views/documents/types/DocumentTypeCreate"
                  )
              },
              meta: {
                barTitle: "Dokumententypen erstellen",
                middleware: [Middleware.auth],
                permissions: ["subject-admin permission"]
              }
            },
            {
              path: "dokumententypen/:id",
              name: "DocumentTypeEdit",
              components: {
                main: () =>
                  import(
            /* webpackChunkName: "auth" */ "../views/documents/types/DocumentTypeEdit"
                  )
              },
              meta: {
                barTitle: "Dokumententypen bearbeiten",
                middleware: [Middleware.auth],
                permissions: ["subject-admin permission"]
              }
            },
            {
              path: "/dokumenteformats",
              name: "DocumentFormats",
              components: {
                main: () =>
                  import(
            /* webpackChunkName: "auth" */ "../views/documents/formats/Formats"
                  )
              },
              meta: {
                barTitle: "Dateiformate",
                middleware: [Middleware.auth],
                permissions: ["subject-admin permission"]
              }
            },
            {
              path: "dokumenteformats/neu",
              name: "DocumentFormatCreate",
              components: {
                main: () =>
                  import(
            /* webpackChunkName: "auth" */ "../views/documents/formats/FormatCreateEdit"
                  )
              },
              meta: {
                barTitle: "Dateiformat erstellen",
                middleware: [Middleware.auth],
                permissions: ["subject-admin permission"]
              }
            },
            {
              path: "dokumenteformats/:id",
              name: "DocumentFormatEdit",
              components: {
                main: () =>
                  import(
            /* webpackChunkName: "auth" */ "../views/documents/formats/FormatCreateEdit"
                  )
              },
              meta: {
                barTitle: "Dateiformat bearbeiten",
                middleware: [Middleware.auth],
                permissions: ["subject-admin permission"]
              }
            },
            {
              path: "/produkte",
              name: "Products",
              components: {
                main: () =>
                  import(
            /* webpackChunkName: "auth" */ "../views/products/Products"
                  )
              },
              meta: {
                barTitle: "Produkte",
                middleware: [Middleware.auth],
                permissions: ["subject-admin permission"]
              }
            },
            {
              path: "produkte/neu",
              name: "ProductCreate",
              components: {
                main: () =>
                  import(
            /* webpackChunkName: "auth" */ "../views/products/ProductCreateEdit"
                  )
              },
              meta: {
                barTitle: "Produkt erstellen",
                middleware: [Middleware.auth],
                permissions: ["subject-admin permission"]
              }
            },
            {
              path: "produkte/:id",
              name: "ProductEdit",
              components: {
                main: () =>
                  import(
            /* webpackChunkName: "auth" */ "../views/products/ProductCreateEdit"
                  )
              },
              meta: {
                barTitle: "Produkt bearbeiten",
                middleware: [Middleware.auth],
                permissions: ["subject-admin permission"]
              }
            },
            {
              path: "/produkteformulare",
              name: "ProductForms",
              components: {
                main: () =>
                  import(
            /* webpackChunkName: "auth" */ "../views/products/forms/Forms"
                  )
              },
              meta: {
                barTitle: "Formulare",
                middleware: [Middleware.auth],
                permissions: ["subject-admin permission"]
              }
            },
            {
              path: "form",
              name: "ProductFormCreate",
              components: {
                main: () =>
                  import(
            /* webpackChunkName: "auth" */ "../views/products/forms/FormsCreateEdit"
                  )
              },
              meta: {
                barTitle: "Formular erstellen",
                middleware: [Middleware.auth],
                permissions: ["subject-admin permission"]
              }
            },
            {
              path: "form/:id",
              name: "ProductFormEdit",
              components: {
                main: () =>
                  import(
            /* webpackChunkName: "auth" */ "../views/products/forms/FormsCreateEdit"
                  )
              },
              meta: {
                barTitle: "Formular bearbeiten",
                middleware: [Middleware.auth],
                permissions: ["subject-admin permission"]
              }
            },
            {
              path: "/nutzer",
              name: "Users",
              components: {
                main: () =>
                  import(
            /* webpackChunkName: "auth" */ "../views/users/Users"
                  )
              },
              meta: {
                barTitle: "Nutzer",
                middleware: [Middleware.auth],
                permissions: ["technical-admin permission"]
              }
            },
            {
              path: "nutzer/neu",
              name: "UserCreate",
              components: {
                main: () =>
                  import(
            /* webpackChunkName: "auth" */ "../views/users/UserCreateEdit"
                  )
              },
              meta: {
                barTitle: "Nutzer erstellen",
                middleware: [Middleware.auth],
                permissions: ["technical-admin permission"]
              }
            },
            {
              path: "nutzer/:id",
              name: "UserEdit",
              components: {
                main: () =>
                  import(
            /* webpackChunkName: "auth" */ "../views/users/UserCreateEdit"
                  )
              },
              meta: {
                barTitle: "Nutzer bearbeiten",
                middleware: [Middleware.auth],
                permissions: ["technical-admin permission"]
              }
            },
            {
              path: "/teams",
              name: "Teams",
              components: {
                main: () =>
                  import(
            /* webpackChunkName: "auth" */ "../views/teams/Teams"
                  )
              },
              meta: {
                barTitle: "Teams",
                middleware: [Middleware.auth],
                permissions: ["technical-admin permission"]
              }
            },
            {
              path: "team/neu",
              name: "TeamCreate",
              components: {
                main: () =>
                  import(
            /* webpackChunkName: "auth" */ "../views/teams/TeamCreateEdit"
                  )
              },
              meta: {
                barTitle: "Team erstellen",
                middleware: [Middleware.auth],
                permissions: ["technical-admin permission"]
              }
            },
            {
              path: "team/:id",
              name: "TeamEdit",
              components: {
                main: () =>
                  import(
            /* webpackChunkName: "auth" */ "../views/teams/TeamCreateEdit"
                  )
              },
              meta: {
                barTitle: "Team bearbeiten",
                middleware: [Middleware.auth],
                permissions: ["technical-admin permission"]
              }
            },
            {
              path: "/kundentypen",
              name: "CustomerTypes",
              components: {
                main: () =>
                  import(
                /* webpackChunkName: "auth" */ "../views/customer/type/Types"
                  )
              },
              meta: {
                barTitle: "Kundentypen",
                middleware: [Middleware.auth],
                permissions: ["technical-admin permission"]
              }
            },
            {
              path: "kundentypen/neu",
              name: "CustomerTypeCreate",
              components: {
                main: () =>
                  import(
                /* webpackChunkName: "auth" */ "../views/customer/type/TypeCreateEdit"
                  )
              },
              meta: {
                barTitle: "Kundentypen erstellen",
                middleware: [Middleware.auth],
                permissions: ["technical-admin permission"]
              }
            },
            {
              path: "kundentypen/:id",
              name: "CustomerTypeEdit",
              components: {
                main: () =>
                  import(
                /* webpackChunkName: "auth" */ "../views/customer/type/TypeCreateEdit"
                  )
              },
              meta: {
                barTitle: "Kundentypen bearbeiten",
                middleware: [Middleware.auth],
                permissions: ["technical-admin permission"]
              }
            },
            {
              path: "/anfragen/statistiken",
              name: "InquiriesStatistics",
              components: {
                main: () =>
                  import(
            /* webpackChunkName: "auth" */ "../views/inquiries/Statistics"
                  )
              },
              meta: {
                barTitle: "Anfragen - Statistiken",
                middleware: [Middleware.auth],
                permissions: ["technical-admin permission"]
              }
            },
            {
              path: "/anfragen/einstellungen/:id",
              name: "InquiriesSettings",
              components: {
                main: () =>
                  import(
            /* webpackChunkName: "auth" */ "../views/inquiries/Settings"
                  )
              },
              meta: {
                barTitle: "Anfragen Einstellungen",
                middleware: [Middleware.auth],
                permissionsAny: [
                  "consultant permission",
                  "technical-admin permission"
                ]
              }
            },
          ]
        }
       ]},
        {
          path: "/system",
          name: "System",
          components: {
            main: () =>
              import(
            /* webpackChunkName: "auth" */ "../views/system/System"
              )
          },
          meta: {
            barTitle: "Systemeinstellungen",
            middleware: [Middleware.auth],
            permissions: ["technical-admin permission"]
          }
        },
        {
          path: "/profil/:id",
          name: "UserProfile",
          components: {
            main: () =>
              import(/* webpackChunkName: "auth" */ "../views/account/Account")
          },
          meta: {
            barTitle: "Profil",
            middleware: [Middleware.auth]
          }
        },
        {
          path: "/shares/:id",
          name: "SharesShow",
          components: {
            main: () =>
              import(
            /* webpackChunkName: "auth" */ "../views/shares/Shares"
              )
          },
          meta: {
            barTitle: "Geteiltes Objekt",
            middleware: [Middleware.auth],
            permissions: ["consultant permission"]
          }
        },
        {
          path: "/shares-section/:id",
          name: "SharesInquirySectionShow",
          components: {
            main: () =>
              import(
            /* webpackChunkName: "auth" */ "../views/shares/InquirySectionShares"
              )
          },
          meta: {
            barTitle: "Geteiltes Objekt",
            middleware: [Middleware.auth],
            permissions: ["consultant permission"]
          }
        },
        {
          path: "/teams-column",
          name: "TeamsColumn",
          components: {
            main: () =>
              import(
            /* webpackChunkName: "auth" */ "../views/teams/TeamsColumnView"
              )
          },
          meta: {
            barTitle: "Ihre Teams",
            middleware: [Middleware.auth],
            permissions: ["consultant permission"]
          }
        },
        {
          path: "/teams/:id",
          name: "TeamsShow",
          components: {
            main: () =>
              import(
            /* webpackChunkName: "auth" */ "../views/teams/TeamsDetails"
              )
          },
          meta: {
            barTitle: "Ansicht Team",
            middleware: [Middleware.auth],
            permissions: ["consultant permission"]
          }
        }

      ]
    },

    ]

const router = new VueRouter({
      mode: 'history',
      base: "",
      routes,
      scrollBehavior(to, from, savedPosition) {
        if (savedPosition) {
          return savedPosition;
        } else {
          return { x: 0, y: 0 };
        }
      }
    })
function nextCheck(context, middleware, index) {
    const nextMiddleware = middleware[index];

    if(!nextMiddleware) return context.next;

    return(...parameters) => {
  context.next(...parameters);
  const nextMidd = nextCheck(context, middleware, index + 1);

  nextMiddleware({ ...context, next: nextMidd });
};
}

router.beforeEach((to, from, next) => {
  if (to.meta.middleware) {
    const middleware = Array.isArray(to.meta.middleware)
      ? to.meta.middleware
      : [to.meta.middleware];

    const ctx = {
      from,
      next,
      router,
      to
    };

    const nextMiddleware = nextCheck(ctx, middleware, 1);

    return middleware[0]({ ...ctx, next: nextMiddleware });
  }

  return next();
});

router.afterEach(to => {
  Vue.nextTick(() => {
    document.title = to.meta.title
      ? to.meta.title + " • fileCQ"
      : to.meta.barTitle
        ? to.meta.barTitle + " • fileCQ"
        : "fileCQ";
  });
});
export default router
