<template>
  <div>
    <v-row>
      <v-col
        cols="12"
        md="12"
        v-for="inquiry in inquiryIndex.assignedInquiries.slice(0, 3)"
        :key="inquiry.id"
      >
        <util-inquiry-card :inquiryProp="inquiry" />
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  props: {},
  data: () => ({}),
  computed: {
    inquiryIndex() {
      return this.$store.getters["inquiry/index"];
    },
  },
  watch: {},
  created() {},
  methods: {},
};
</script>

<style scoped></style>