<template>
  <v-dialog max-width="600px" v-model="dialog" persistent>
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        color="primary"
        block
        v-bind="attrs"
        v-on="on"
        v-if="!milestoneProp"
      >
        {{
          milestoneProp ? "Zwischenziel bearbeiten" : "Zwischenziel hinzufügen"
        }}
        <v-icon right dark>mdi-plus</v-icon>
      </v-btn>
      <v-btn icon v-bind="attrs" v-on="on" v-else>
        <v-icon>mdi-pencil-outline</v-icon>
      </v-btn>
    </template>
    <ui-dialog-card
      :title="
        milestoneProp ? 'Zwischenziel bearbeiten' : 'Zwischenziel hinzufügen'
      "
    >
      <template v-slot:text>
        <v-form @submit.prevent="submit" v-model="isValid">
          <v-text-field
            v-model="milestone.name"
            filled
            label="Name*"
            :rules="[rules.required, rules.short_text]"
            :counter="280"
            solo
          />
          <v-textarea
            v-model="milestone.description"
            filled
            label="Beschreibung"
            :rules="[rules.long_text]"
            :counter="6000"
            solo
          />
          <v-checkbox
            v-model="milestone.is_autorelease"
            label="Zwischenziel wird automatisch erreicht"
          />
        </v-form>
      </template>

      <template v-slot:actions>
        <v-spacer />
        <v-btn text @click="close" class="mr-2">
          Abbrechen
        </v-btn>
        <v-btn
          color="primary"
          @click="submit"
          :disabled="!isValid"
          :loading="loading"
        >
          {{ milestoneProp ? "Abschnitt speichern" : "Abschnitt hinzufügen" }}
          <v-icon right dark v-if="!milestoneProp">
            mdi-plus
          </v-icon>
        </v-btn>
      </template>
    </ui-dialog-card>
  </v-dialog>
</template>

<script>
import { mapActions } from "vuex";

export default {
  props: {
    isTemplate: {
      type: Boolean,
      default: false
    },
    milestoneProp: {
      type: Object,
      default: () => {}
    }
  },
  data: () => ({
    milestone: {
      type: "milestone"
    },

    // functional
    releasedAtModal: false,
    dialog: false,
    isValid: false,
    loading: false
  }),
  computed: {
    rules() {
      return this.$store.getters["application/rules"];
    },
    formattedReleasedAt() {
      return this.milestone.released_at
        ? this.$moment(this.milestone.released_at).format("DD.MM.YYYY")
        : null;
    },
    currentDate() {
      return this.$moment(new Date()).format("YYYY-MM-DD");
    }
  },
  watch: {
    milestoneProp(val) {
      this.milestone = val;
    }
  },
  created() {
    this.milestone = this.milestoneProp ? this.milestoneProp : this.milestone;
  },
  methods: {
    ...mapActions({
      showCurrentInquiry: "inquiry/showInquiry",
      indexAvailableDocuments: "inquiry/indexAvailableDocuments",
      patchInquiryItem: "inquiryItem/patch"
    }),

    patchMilestone() {
      this.patchInquiryItem({ id: this.milestone.id, payload: this.milestone })
        .then(() => {
          this.$snotify.success("Der Abschnitt wurde erfolgreich bearbeitet.");
          this.close();
        })
        .catch(e => {
          console.log(e);
          this.$snotify.error(
            "Beim Bearbeiten des Abschnitts ist ein Fehler aufgetreten."
          );
        });
    },
    storeMilestone() {
      this.milestone.inquiry_id = this.$route.params.id;
      this.axios
        .post("inquiry-items", {
          inquiryItem: this.milestone
        })
        .then(() => {
          this.showCurrentInquiry(this.$route.params.id);
          this.$snotify.success("Der Abschnitt wurde erfolgreich hinzugefügt.");
          this.close();
        })
        .catch(e => {
          console.log(e);
          this.$snotify.error(
            "Beim Hinzufügen des Abschnitts ist ein Fehler aufgetreten."
          );
        });
    },

    // functional
    close() {
      this.dialog = false;
      this.loading = false;
      this.$emit("close");
      this.indexAvailableDocuments();
    },

    submit() {
      this.loading = true;
      this.milestone.id ? this.patchMilestone() : this.storeMilestone();
    }
  }
};
</script>

<style scoped></style>
