import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import axios from "axios";
import VueAxios from "vue-axios";
import moment from "moment";
import Snotify, { SnotifyPosition } from "vue-snotify";
import registerComponents from "@/helpers/registerComponents";
import hasPermission from "@/helpers/hasPermissions";
import draggable from "vuedraggable";
import VueProgressBar from "vue-progressbar";
import "regenerator-runtime/runtime";
import "roboto-fontface/css/roboto/roboto-fontface.css";
import "@mdi/font/css/materialdesignicons.css";
import "core-js/stable";
import "material-design-icons-iconfont/dist/material-design-icons.css";
import SweetAlertIcons from "vue-sweetalert-icons";
import Fragment from "vue-fragment";


Vue.use(VueAxios, axios);
// get backend url
Vue.axios.defaults.baseURL = "https://demov2-api.filecq.com/api/"
Vue.axios.defaults.headers;
Vue.axios.interceptors.request.use(
  function (config) {
    const token = localStorage.getItem("token");
    config.headers.Authorization = `Bearer ${token}`;
    return config;
  },
  function (error) {
    console.log("ERROR ALARM");
    return Promise.reject(error);
  }
);

Vue.use(Fragment.Plugin);

moment.locale("de");
Vue.prototype.$moment = moment;
Vue.use(require("vue-moment"), {
  moment
});

const options = {
  toast: {
    position: SnotifyPosition.rightBottom
  }
};

Vue.use(SweetAlertIcons)
Vue.component("Draggable", draggable);

const progressBarOptions = {
  color: "#000000",
  failedColor: "#d63030",
  thickness: "5px",
  transition: {
    speed: "0.2s",
    opacity: "0.6s",
    termination: 300
  },
  autoRevert: true,
  location: "top",
  inverse: false
};

Vue.use(VueProgressBar, progressBarOptions);

registerComponents();
Vue.prototype.$hasPermissions = hasPermission;
Vue.config.productionTip = false


Vue.use(Snotify, options);
new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
