export default {
    setIndex(state, payload) {
        state.index = payload;
    },
    setCurrentInquiry(state, payload) {
        state.currentInquiry = payload;
    },
    setAvailableDocuments(state, payload) {
        state.availableDocuments = payload;
    }
};
