import router from "@/router";

export default {
  goBack(routeName) {
    if (window.history.length > 2) {
      router.go(-1);
    } else {
      router
        .push({ name: routeName })
        .then()
        .catch();
    }
  }
};
