<template>
  <div class="mb-4">
    <v-card>
      <v-list-item class="primary" dark two-line>
        <v-list-item-action v-if="inquiry.is_extendable || isTemplate">
          <v-btn icon style="cursor: move;" class="handle">
            <v-icon>mdi-drag</v-icon>
          </v-btn>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-subtitle>
            Zwischenziel
          </v-list-item-subtitle>
          <v-list-item-title class="text-h6">
            {{ milestoneProp.name }}
          </v-list-item-title>
          <v-list-item-subtitle v-if="milestoneProp.description">
            {{ milestoneProp.description }}
          </v-list-item-subtitle>
          <v-list-item-subtitle
            v-if="milestoneProp.is_autorelease"
            class="mt-1"
          >
            <v-chip small color="black" dark>
              <v-icon small left>
                mdi-lock-open-outline
              </v-icon>
              <span v-if="!milestoneProp.released_at">
                Automatisch erreicht
              </span>
              <span v-else-if="!isTemplate">
                Freigabe am
                {{ milestoneProp.released_at | moment("DD.MM.YYYY") }}
              </span>
            </v-chip>
          </v-list-item-subtitle>
        </v-list-item-content>
        <v-list-item-action v-if="inquiry.is_extendable || isTemplate">
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <div v-bind="attrs" v-on="on">
                <inquiry-wizard-layout-milestone-dialog
                  :milestone-prop="milestoneProp"
                  :is-template="isTemplate"
                />
              </div>
            </template>
            <span>Bearbeiten</span>
          </v-tooltip>
        </v-list-item-action>
        <v-list-item-action v-if="inquiry.is_extendable || isTemplate">
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                v-bind="attrs"
                v-on="on"
                @click="destroyMilestone"
                :loading="loading"
              >
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </template>
            <span>Entfernen</span>
          </v-tooltip>
        </v-list-item-action>
      </v-list-item>
    </v-card>
  </div>
</template>

<script>
import { mapActions } from "vuex";

export default {
  props: {
    isTemplate: {
      type: Boolean,
      default: false
    },
    milestoneProp: {
      type: Object,
      default: () => {}
    }
  },
  data: () => ({
    // functional
    loading: false
  }),
  computed: {
    inquiry() {
      return this.$store.getters["inquiry/currentInquiry"];
    }
  },
  watch: {},
  created() {},
  methods: {
    ...mapActions({
      destroyInquiryItem: "inquiryItem/destroy",
      showCurrentInquiry: "inquiry/showInquiry"
    }),
    destroyMilestone() {
      this.loading = true;
      this.destroyInquiryItem(this.milestoneProp.id)
        .then(() => {
          this.showCurrentInquiry(this.$route.params.id);
        })
        .catch(e => {
          console.log(e);
          this.$snotify.error(
            "Beim Entfernen des Abschnitts ist ein Fehler aufgetreten."
          );
        })
        .finally(() => {
          this.loading = false;
        });
    },
    patchMilestone() {
      this.$store.dispatch("inquiryItem/patch", {
        id: this.milestoneProp.id,
        payload: this.milestoneProp,
        onlyChildren: true
      });
    }
  }
};
</script>

<style scoped></style>
