<template>
  <v-card>
    <v-card-title
      >Formularfeld hinzufügen
      <util-info-tooltip>
        <template v-slot:text>
          Hier können Sie dem Feld eine Bezeichnung und eine Beschreibung
          hinzufügen, die in der Textzeile oder dem Textfeld angezeigt wird. Mit
          dem Typ legen Sie fest, ob das Feld eine Textzeile, ein Textfeld oder
          eine durch Ihnen vorgegebene Auswahl sein soll.
        </template>
      </util-info-tooltip>
    </v-card-title>
    <v-card-text>
      <v-form
        lazy-validation
        v-model="isValid"
        ref="form"
        @submit.prevent="pushFormItem"
      >
        <v-text-field
          v-model="formItem.name"
          filled
          label="Bezeichnung"
          :rules="[rules.label_text, rules.required]"
          :counter="50"
          solo
        />
        <v-textarea
          v-model="formItem.description"
          filled
          label="Platzhalter"
          :rules="[rules.label_text]"
          :counter="50"
           solo
        />

        <v-select
          v-model="formItem.type"
          :items="formItemTypes"
          filled
          label="Typ"
          solo
        />

        <div v-if="formItem.type === 'select'">
          <v-list-item
            dense
            v-for="(item, index) in formItem.items"
            :key="index"
            class="grey lighten-4"
            :class="index > 0 ? 'mt-4' : ''"
          >
            <v-list-item-title>{{ item }}</v-list-item-title>
            <v-list-item-action
              @click="formItem.items.splice(index, 1)"
              style="cursor: pointer;"
            >
              <v-icon>mdi-close</v-icon>
            </v-list-item-action>
          </v-list-item>
          <v-text-field
            label="Auswahlmöglichkeit"
            v-model="selectItem"
            append-icon="mdi-plus"
            @click:append="pushSelectItem()"
          />
        </div>
        <div class="d-flex justify-end">
          <v-btn text @click="$emit('closeDialog')">Abbrechen</v-btn>
          <v-btn
            color="primary"
            class="ml-2"
            type="submit"
            :disabled="!isValid"
          >
            Speichern
          </v-btn>
        </div>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  data: () => ({
    isValid: false,
    formItem: {
      type: "",
      name: "",
      description: "",
      items: []
    },
    selectItem: ""
  }),
  mounted() {},
  computed: {
    rules() {
      return this.$store.getters["application/rules"];
    },
    formItemTypes() {
      return this.$store.getters["application/formItemTypes"];
    }
  },
  methods: {
    pushFormItem() {
      this.$emit("pushFormItem", this.formItem);
      this.$emit("closeDialog");
    },
    pushSelectItem() {
      this.formItem.items.push(this.selectItem);
      this.selectItem = null;
    }
  }
};
</script>

<style scoped></style>
