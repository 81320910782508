<template>
  <v-dialog max-width="600px" v-model="dialog" persistent>
    <template v-slot:activator="{ on, attrs }">
      <v-btn depressed v-bind="attrs" v-on="on">
        Löschen
        <v-icon right color="error">mdi-delete-outline</v-icon>
      </v-btn>
    </template>
    <ui-dialog-card
      :title="inquiry.is_template ? 'Vorlage löschen' : 'Anfrage löschen'"
    >
      <template v-slot:text>
        <span v-if="inquiry.is_template">
          Beim Löschen einer Vorlage wird diese in einen Archiviert-Zustand
          versetzt, um bestehende Verknüpfungen mit aktiven Anfragen nicht zu
          gefährden. <br />
          Die Vorlage ist für Berater jedoch nicht mehr auswählbar.
        </span>
        <span v-else>
          Beim Löschen einer Anfrage wird diese unwiderruflich gelöscht.
        </span>
      </template>

      <template v-slot:actions>
        <v-spacer />
        <v-btn text @click="close" class="mr-2">
          Abbrechen
        </v-btn>
        <v-btn color="error" @click="submit" :loading="loading">
          {{ inquiry.is_template ? "Vorlage löschen" : "Anfrage löschen" }}
          <v-icon right dark>
            mdi-delete-outline
          </v-icon>
        </v-btn>
      </template>
    </ui-dialog-card>
  </v-dialog>
</template>

<script>
import { mapActions } from "vuex";

export default {
  props: {},
  data: () => ({
    // functional
    dialog: false,
    isValid: false,
    loading: false
  }),
  computed: {
    inquiry() {
      return this.$store.getters["inquiry/currentInquiry"];
    },
    rules() {
      return this.$store.getters["application/rules"];
    }
  },
  watch: {
    itemProp(val) {
      this.section = val;
    }
  },
  created() {
    // this.item = this.itemProp ? this.itemProp : this.item;
  },
  methods: {
    ...mapActions({
      destroyInquiry: "inquiry/destroy"
    }),

    // functional
    close() {
      this.dialog = false;
      this.loading = false;
      this.$emit("close");
    },

    submit() {
      this.loading = true;
      this.destroyInquiry(this.$route.params.id)
        .then(() => {
          const successMessage = this.inquiry.is_template
            ? "Die Vorlage wurde erfolgreich gelöscht."
            : "Die Anfrage wurde erfolgreich gelöscht.";

          this.$snotify.success(successMessage);
          this.inquiry.is_template
            ? this.$router.push({ name: "SettingsInquiries" })
            : this.$router.push({ name: "Inquiries" });
        })
        .catch(e => {
          console.log(e);
          const errorMessage = this.inquiry.is_template
            ? "Beim Löschen der Vorlage ist ein Fehler aufgetreten."
            : "Beim Löschen der Anfrage ist ein Fehler aufgetreten.";
          this.$snotify.error(errorMessage);
        });
    }
  }
};
</script>

<style scoped></style>
