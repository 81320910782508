<template>
  <div>
    <inquiry-consultant-timeline :is-preview="true" />
  </div>
</template>

<script>
export default {
  props: {},
  data: () => ({}),
  computed: {},
  watch: {},
  created() {},
  methods: {}
};
</script>

<style scoped></style>
