<template>
  <v-dialog max-width="600px" v-model="dialog" persistent>
    <template v-slot:activator="{ on, attrs }">
      <!-- is used by wizard and show -->
      <v-btn depressed v-bind="attrs" v-on="on" v-if="isWizard">
        Dokument hinzufügen
        <v-icon right>mdi-plus</v-icon>
      </v-btn>
      <v-list-item link v-bind="attrs" v-on="on" v-else>
        Dokument hinzufügen
      </v-list-item>
    </template>
    <ui-dialog-card title="Dokument hinzufügen">
      <template v-slot:text>
        <v-text-field
          solo
          solo-inverted
          flat
          placeholder="Suchen"
          prepend-inner-icon="mdi-magnify"
          dense
          hide-details
          v-model="search"
         
        />
        <util-documents-tree-view
          :search="search"
          :select="true"
          :selectable="true"
          @input="selectedDocuments = $event"
          :documents="documents"
          :key="key"
        />
      </template>

      <template v-slot:actions>
        <v-spacer />
        <v-btn text @click="close" class="mr-2">
          Abbrechen
        </v-btn>
        <v-btn
          color="primary"
          @click="addInquiryItems"
          :disabled="!selectedDocuments || selectedDocuments.length < 1"
          :loading="loading"
        >
          Dokumente hinzufügen
          <v-icon right dark>
            mdi-plus
          </v-icon>
        </v-btn>
      </template>
    </ui-dialog-card>
  </v-dialog>
</template>

<script>
import { mapActions } from "vuex";

export default {
  props: {
    sectionId: {
      type: String,
      default: ""
    },
    isWizard: {
      type: Boolean,
      default: true
    }
  },
  data: () => ({
    dialog: false,
    selectedDocuments: [],
    search: "",
    loading: false,
    key: 0
  }),
  computed: {
    documents() {
      return this.$store.getters["inquiry/availableDocuments"];
    }
  },
  watch: {},
  created() {},
  methods: {
    ...mapActions({
      indexAvailableDocuments: "inquiry/indexAvailableDocuments"
    }),

    close() {
      this.key++;
      this.loading = false;
      this.dialog = false;
      this.$emit("close");
      this.selectedDocuments = [];
    },

    addInquiryItems() {
      this.loading = true;
      this.axios
        .post("/inquiries/" + this.$route.params.id + "/add-inquiry-items", {
          attached_ids: this.selectedDocuments,
          parent_id: this.sectionId
        })
        .then(r => {
          this.$store.commit(
            "inquiry/setCurrentInquiry",
            r.data.inquiry
          );
          this.indexAvailableDocuments();
          this.$emit("close");
          this.$snotify.success(
            "Die Dokumente wurden erfolgreich der Anfrage hinzugefügt."
          );
          this.close();
        })
        .catch(e => {
          console.log(e);
          this.$snotify.error(
            "Beim Hinzufügen der Dokumente ist ein Fehler aufgetreten."
          );
        })
        .finally(() => {
          this.loading = false;
        });
    }
  }
};
</script>

<style scoped></style>
