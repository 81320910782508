<template>
  <div>
    <div class="text-overline mb-4">
      Zuweisung
    </div>
    <v-select
      v-model="inquiry.assignable_type"
      @input="setAssignee()"
      filled
      :items="[
        { text: 'Berater', value: 'App\\Models\\User' },
        { text: 'Team', value: 'App\\Models\\Team' }
      ]"
      label="Bearbeitertyp"
      :rules="[rules.required]"
      required
      solo
    />

    <v-autocomplete
      v-if="inquiry.assignable_type === 'App\\Models\\User'"
      v-model="inquiry.assignable_id"
      :items="users_intern"
      filled
      label="Berater"
      :rules="[rules.required]"
      required
      item-text="name"
      item-value="id"
      solo
    >
      <template v-slot:item="data">
        <v-list-item-content>
          <v-list-item-title>{{ data.item.name }}</v-list-item-title>
          <!--              <v-list-item-subtitle v-if="data.item.label">
            {{ data.item.label }}
          </v-list-item-subtitle>-->
          <v-list-item-subtitle v-if="data.item.email">
            {{ data.item.email }}
          </v-list-item-subtitle>
        </v-list-item-content>
      </template>
    </v-autocomplete>

    <v-autocomplete
      v-if="inquiry.assignable_type === 'App\\Models\\Team'"
      v-model="inquiry.assignable_id"
      :items="teams"
      filled
      label="Team"
      :rules="[rules.required]"
      required
      item-text="name"
      item-value="id"
      solo
    >
      <template v-slot:item="data">
        <v-list-item-content>
          <v-list-item-title>{{ data.item.name }}</v-list-item-title>
          <!--              <v-list-item-subtitle v-if="data.item.label">
            {{ data.item.label }}
          </v-list-item-subtitle>-->
          <v-list-item-subtitle v-if="data.item.description">
            {{ data.item.description }}
          </v-list-item-subtitle>
        </v-list-item-content>
      </template>
    </v-autocomplete>

    <v-autocomplete
      v-if="inquiry.assignable_type === 'App\\Models\\Team'"
      v-model="inquiry.contact_person_id"
      :items="users_intern"
      filled
      label="Ansprechpartner"
      :rules="[rules.required]"
      required
      item-text="name"
      item-value="id"
      solo
    >
      <template v-slot:item="data">
        <v-list-item-content>
          <v-list-item-title>{{ data.item.name }}</v-list-item-title>
          <!--              <v-list-item-subtitle v-if="data.item.label">
            {{ data.item.label }}
          </v-list-item-subtitle>-->
          <v-list-item-subtitle v-if="data.item.email">
            {{ data.item.email }}
          </v-list-item-subtitle>
        </v-list-item-content>
      </template>
    </v-autocomplete>

    <v-card-actions class="pa-0">
      <v-spacer />
      <v-btn color="primary" @click="patchInquiry" :loading="loading">
        Speichern
      </v-btn>
    </v-card-actions>
  </div>
</template>

<script>
export default {
  props: {},
  data: () => ({
    users_intern: [],
    teams: [],
    loading: false
  }),
  computed: {
    inquiry() {
      return this.$store.getters["inquiry/currentInquiry"];
    },
    rules() {
      return this.$store.getters["application/rules"];
    },
    userDetails() {
      return this.$store.getters["authentication/userDetails"];
    }
  },
  watch: {},
  created() {
    this.indexTeams();
    this.indexUsers();
  },
  methods: {
    patchInquiry() {
      this.loading = true;
      this.axios
        .patch("/inquiries/" + this.$route.params.id, {
          inquiry: this.inquiry
        })
        .then(() => {
          this.$snotify.success(
            "Die Änderungen wurden erfolgreich gespeichert."
          );
        })
        .catch(e => {
          console.log(e);
          this.$snotify.error("Beim Speichern ist ein Fehler aufgetreten.");
        })
        .finally(() => {
          this.loading = false;
        });
    },

    setAssignee() {
      if (this.$route.name === "InquiriesForm") {
        if (this.inquiry.assignable_type === "App\\Models\\User") {
          this.inquiry.assignable_id = this.userDetails.id;
          this.inquiry.contact_person_id = "";
        }
        if (this.inquiry.assignable_type === "App\\Models\\Team") {
          this.inquiry.assignable_id = "";
          this.inquiry.contact_person_id = this.userDetails.id;
        }
      }
    },
    indexTeams() {
      this.axios
        .get("/teams")
        .then(res => {
          this.teams = res.data.teams;
        })
        .catch(err => {
          console.log(err);
          this.$snotify.error("Ein Fehler ist aufgetreten.");
        });
    },
    indexUsers() {
      this.axios
        .get("/users")
        .then(res => {
          this.users_intern = res.data.users_intern;
        })
        .catch(err => {
          console.log(err);
          this.$snotify.error("Ein Fehler ist aufgetreten.");
        });
    }
  }
};
</script>

<style scoped></style>
