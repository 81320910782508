export default {
    setBreadCrumbs(state, payload) {
      state.breadCrumbs = payload;
    },
    setDrawerOpen(state, payload) {
      state.isDrawerOpen = payload;
    },
    isLoginForm(state, payload) {
      state.isInquiryViewColumn = payload;
    }
  };
  