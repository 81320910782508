<template>
  <div class="mt-n4">
    <v-form lazy-validation ref="form" v-model="isValid">
      <inquiry-wizard-general-basic-form
        :isTemplate="isTemplate"
        @validation="isValidBasic = $event"
      />

      <div>
        <div >
        <div class="text-overline">
          Beschreibung:
        </div>
          <v-textarea
            v-model="inquiry.description"
            filled
            label="Beschreibung"
            :rules="[rules.long_text]"
            :counter="6000"
            solo
            class="mt-2"
          />

          <!-- <div class="text-overline mt-4">
            Hilfestellung

            <util-info-tooltip>
              <template v-slot:text>
                In der Hilfestellung können Sie jeweils eine Bearbeitungsnotiz
                für Berater*in und Kunde/Kundin hinterlegen. Geben Sie hier
                Hinweise, was entweder der/die Berater*in bei der Bearbeitung
                oder der/die Kunde/Kundin bei der Einreichung beachten sollte.
                Der Kunde/die Kundin kann nur die Bearbeitungsnotiz für den
                Kunden einsehen, der/die Berater*in sieht jedoch sowohl die
                Bearbeitungsnotiz für den Kunden, als auch die Bearbeitungsnotiz
                für den Berater.
              </template>
            </util-info-tooltip>
          </div> -->
          <div class="text-overline">
            Bearbeitungsnotiz für den Berater:
               </div>
            <v-textarea
              v-model="inquiry.hint"
              filled
              label="Bearbeitungsnotiz für den Berater"
              :rules="[rules.long_text]"
              :counter="6000"
              solo
              class="mt-2"
            />
       
        <div class="text-overline">
          Bearbeitungsnotiz für den Kunden:
              </div>
          <v-textarea
            v-model="inquiry.help"
            filled
            label="Bearbeitungsnotiz für den Kunden"
            :rules="[rules.long_text]"
            :counter="6000"
            solo
              class="mt-2"
          />
    
        </div>

        <div v-if="isTemplate">
          <div class="text-overline mt-4">Einstellungen</div>
          <v-checkbox
            v-model="inquiry.is_extendable"
            label="Vorlage ist erweiter- und editierbar."
          />
        </div>
      </div>

      <v-alert
        type="warning"
        dense
        text
        v-if="!inquiry.inquirySections || inquiry.inquirySections.length < 1"
      >
        Sie haben noch keine Anforderungen festlegt!
      </v-alert>
      <v-card-actions class="pa-0">
        <v-spacer />
        <v-btn
          color="primary"
          text
          @click="
            inquiry.is_draft = true;
            patchInquiry();
          "
          :loading="loading"
          class="mr-2"
        >
          Als Entwurf speichern
        </v-btn>
        <v-btn
          color="primary"
          @click="
            inquiry.is_draft = false;
            patchInquiry({ finish: true });
          "
          :disabled="
            !isValid ||
            !isValidBasic ||
            !inquiry.inquirySections ||
            inquiry.inquirySections.length < 1
          "
          :loading="loading"
        >
          Speichern und freigeben
        </v-btn>
      </v-card-actions>
    </v-form>

    <!-- advanced -->
    <div class="overline mt-4">Erweitert</div>
    <div class="mb-4">
      <div class="text-h6">Löschen</div>
      <div class="body-1">
        <span v-if="isTemplate">
          Beim Löschen einer Vorlage wird diese in einen Archiviert-Zustand
          versetzt, um bestehende Verknüpfungen mit aktiven Anfragen nicht zu
          gefährden. <br />
          Die Vorlage ist für Berater jedoch nicht mehr auswählbar.
        </span>
        <span v-else>
          Beim Löschen einer Anfrage wird diese unwiderruflich gelöscht.
        </span>
      </div>

      <div class="mt-2">
        <inquiry-wizard-general-delete-dialog />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    isTemplate: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    loading: false,
    isValid: false,
    isValidBasic: false,
  }),
  computed: {
    inquiry() {
      return this.$store.getters["inquiry/currentInquiry"];
    },
    rules() {
      return this.$store.getters["application/rules"];
    },
  },
  watch: {
    inquiry() {
      this.setBreadCrumbs();
    },
  },
  created() {
    this.setBreadCrumbs();
  },
  methods: {
    patchInquiry({ finish }) {
      this.loading = true;
      this.$store
        .dispatch("inquiry/patch", {
          id: this.$route.params.id,
          payload: this.inquiry,
          finish: finish,
        })
        .then(() => {
          console.log("ovo pecujemo" + this.inquiry.data);
          const successMessage = this.isTemplate
            ? "Die Vorlage wurde erfolgreich gespeichert."
            : "Die Anfrage wurde erfolgreich gespeichert.";
          this.$snotify.success(successMessage);
          if (!this.isTemplate && finish) {
            this.$router.push({
              name: "InquiriesShow",
              params: { id: this.$route.params.id },
            });
          }
        })
        .catch((e) => {
          console.log(e);
          const errorMessage = this.isTemplate
            ? "Beim Speichern der Vorlage ist ein Fehler aufgetreten."
            : "Beim Anfrage der Vorlage ist ein Fehler aufgetreten.";
          this.$snotify.error(errorMessage);
        })
        .finally(() => {
          this.loading = false;
        });
    },

    // functional

    // ui
    setBreadCrumbs() {
      if (this.inquiry && this.inquiry.name) {
        let breadCrumbs = [
          {
            text: this.isTemplate ? "Einstellungen: Vorlagen" : "Anfragen",
            to: this.isTemplate
              ? { name: "SettingsInquiries" }
              : { name: "Inquiries" },
            disabled: false,
            exact: true,
          },
          {
            text:
              (this.isTemplate ? "Vorlage: " : "Anfrage: ") + this.inquiry.name,
            to: this.isTemplate
              ? {
                  name: "SettingsInquiriesEditGeneral",
                  params: { id: this.$route.params.id },
                }
              : {
                  name: "InquiriesEditGeneral",
                  params: { id: this.$route.params.id },
                },
            exact: true,
            disabled: false,
          },
        ];
        this.$store.commit("application/setBreadCrumbs", breadCrumbs);
      }
    },
  },
};
</script>

<style scoped></style>
