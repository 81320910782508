import InquiryItemService from "@/services/inquiry-item.service.js";

export default {
    show(ctx, id) {
       return InquiryItemService.show(ctx, id);
    },
    patch(ctx,{ id, payload, onlyChildren }) {
        return InquiryItemService.patch(ctx,{ id, payload, onlyChildren });
    },
    destroy(ctx,id) {
        return InquiryItemService.destroy(ctx,id);
    },
    statusIcon({inquiryItem}){
        return InquiryItemService.statusIcon({inquiryItem})
    },
    statusText({inquiryItem}){
        return  InquiryItemService.statusText({inquiryItem})
    },
    statusActionText({inquiryItem}){
        return InquiryItemService.statusActionText({inquiryItem})
    },
    statusActionIcon({inquiryItem}){
        return InquiryItemService.statusActionIcon({inquiryItem})
    },
}