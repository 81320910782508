<template>
  <div>
    <v-card-title>
      <span class="headline">
        Zugriffsberechtigungen verwalten
      </span>
    </v-card-title>
    <v-card-text>
      <v-form ref="form">
        <div class="text-overline mb-4">
          Zugriffsberechtigung hinzufügen
        </div>
        <v-select
          v-model="share.accessor_type"
          :items="[
            { value: 'user', text: 'Berater' },
            { value: 'team', text: 'Team' }
          ]"
          filled
          label="Zugreifertyp"
          :rules="[rules.required]"
          required
          solo
        />

        <v-select
          v-if="share.accessor_type === 'user'"
          v-model="share.accessor_id"
          :items="users"
          filled
          label="Berater"
          :rules="[rules.required]"
          required
          item-text="name"
          item-value="id"
          solo
        />

        <v-select
          v-if="share.accessor_type === 'team'"
          v-model="share.accessor_id"
          :items="teams"
          filled
          label="Team"
          :rules="[rules.required]"
          required
          item-text="name"
          item-value="id"
            solo
        />

        <v-textarea
          v-if="share.accessor_type && share.accessor_id"
          filled
          v-model="share.hint"
          label="Hinweis für die Bearbeiter"
            solo
        />

        <div
          class="d-flex justify-end"
          v-if="share.accessor_type && share.accessor_id"
        >
          <v-btn
            text
            @click="
              share.accessor_type = '';
              share.accessor_id = '';
              share.hint = '';
            "
          >
            Abbrechen
          </v-btn>
          <v-btn color="primary" class="ml-2" @click="storeShare">
            Hinzufügen
            <v-icon right dark>mdi-plus</v-icon>
          </v-btn>
        </div>
      </v-form>
    </v-card-text>
    <v-card-text v-if="item && item.shares">
      <div class="text-overline mb-4">
        Erteilte Zugriffsberechtigungen ({{ item.shares.length }})
      </div>
      <div v-if="item.shares.length < 1" class="body-1">
        Es wurden bisher keine Zugriffe erteilt.
      </div>
      <div v-else>
        <v-list-item v-for="share in item.shares" :key="share.id" class="px-0">
          <v-list-item-content>
            <v-list-item-title>
              {{ share.created_at | moment("DD.MM.YYYY HH:mm") }}
            </v-list-item-title>
            <v-list-item-subtitle>
              Erteilt an
              {{
                share.accessor_type === "App\\Models\\Team"
                  ? "Team: "
                  : "Berater: "
              }}
              {{ share.accessor.name }}
            </v-list-item-subtitle>
          </v-list-item-content>
          <v-list-item-action>
            <v-btn
              depressed
              :to="{ name: 'SharesShow', params: { id: share.id } }"
            >
              Bearbeiten
              <v-icon right>mdi-eye</v-icon>
            </v-btn>
          </v-list-item-action>
        </v-list-item>
      </div>
    </v-card-text>
  </div>
</template>
<script>
import { mapActions } from "vuex";

export default {
  props: {
    type: String
  },
  data: () => ({
    share: {
      accessor_type: "",
      accessor_id: "",
      hint: ""
    },
    teams: [],
    users: []
  }),
  computed: {
    rules() {
      return this.$store.getters["application/rules"];
    },

    item() {
      switch (this.type) {
        case "inquiry":
          return this.$store.getters["inquiry/currentInquiry"];
      }
      return this.$store.getters["inquiry/currentInquiry"];
    },
    shareableType() {
      switch (this.type) {
        case "inquiry":
          return "App\\Models\\Inquiry";
      }
      return "App\\Models\\Inquiry";
    }
  },
  watch: {},
  created() {
    this.indexTeams();
    this.indexUsers();
  },
  methods: {
    ...mapActions({
      vuexShowCurrentInquiry: "inquiry/showInquiry"
    }),
    storeShare() {
      let item = this.share;
      item.shareable_type = this.shareableType;
      item.shareable_id = this.$route.params.id;
      item.accessor_rights = "write";
      this.axios
        .post("/shares", {
          share: item
        })
        .then(() => {
          this.$snotify.success(
            "Die Zugriffsberechtigung wurde erfolgreich erteilt."
          );
          this.share = {};
          this.$refs.form.resetValidation();
          switch (this.type) {
            case "inquiry":
              this.vuexShowCurrentInquiry(this.$route.params.id);
          }
        })
        .catch(e => {
          console.log(e);
        });
    },

    indexTeams() {
      this.axios
        .get("/teams")
        .then(res => {
          this.teams = res.data.teams;
          this.teams_archived = res.data.teams_archived;
        })
        .catch(err => {
          console.log(err);
          this.$snotify.error("Ein Fehler ist aufgetreten.");
        });
    },

    indexUsers() {
      this.axios
        .get("/users")
        .then(r => {
          this.users = r.data.users_intern;
        })
        .catch(e => {
          console.log(e);
        });
    }
  }
};
</script>

<style scoped></style>
