import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import de from "vuetify/lib/locale/de";
import "@mdi/font/css/materialdesignicons.css";
//import colors from 'vuetify/lib/util/colors'
Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        options: {
          customProperties: true
        },
        themes: {
          light: {
            primary: "#a4bafb",
            secondary: "#44319b",
            accent: "#82B1FF",
            white: "#FFFFFF"
          }
        }
      },
      lang: {
        locales: { de },
        current: "de"
      },
      icons: {
        iconfont: "mdi"
      }
  })