export default {
    isLoggedIn(state) {
      return state.isLoggedIn;
    },
    permissions(state) {
      return state.permissions;
    },
    roles(state) {
      return state.roles;
    },
    userDetails(state) {
      return state.userDetails;
    },
    settings(state) {
        return state.settings;
    },
    brandUrl(state) {
        return state.brandUrl;
    }
  };
  